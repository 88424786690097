import React, { useState,useEffect, useContext, useCallback, useMemo } from 'react';
import { getReponseApi } from '../apis/api_backend';
import { PayEcoleContext } from '../../contexts/PayEcoleContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faSave, 
  faEraser, 
  faSpinner,
  faShoppingCart
} from '@fortawesome/free-solid-svg-icons';
import '../styles/ImportTab.css';

const INITIAL_FORM_STATE = {
  nom_produit: '',
  nom_client: '',
  tel_client: '',
  montant: '',
  factures: 1
};

const DonneesTab = () => {
  const { user} = useContext(PayEcoleContext);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [structureLocal, setStructureLocal] = useState(null);
  const formValidation = useMemo(() => {
    const errors = {};
    if (!formData.nom_produit?.trim()) {
      errors.nom_produit = 'Le nom du produit est requis';
    }
    if (!formData.montant || formData.montant <= 0) {
      errors.montant = 'Le montant doit être supérieur à 0';
    }
    if (formData.tel_client && !/^\d{9}$/.test(formData.tel_client)) {
      errors.tel_client = 'Le numéro de téléphone doit contenir 9 chiffres';
    }
    if (formData.factures < 1) {
      errors.factures = 'Le nombre de factures doit être au moins 1';
    }
    return errors;
  }, [formData]);

  const isFormValid = Object.keys(formValidation).length === 0;

  useEffect(() => {
    const fetchStructure = async () => {
      try {
        if (user && user.id_structure ) {
    
          const response = await getReponseApi(`/structures/${user.id_structure}`, 'GET');

          if (response && Array.isArray(response) && response.length > 0) {
            const structureData = response[0];
            console.log('structureData : ',structureData);
            setStructureLocal(structureData);
            
          }
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des informations de la structure:', error);
      } finally {
        
      }
    };
    if (!structureLocal && user && user.id_structure) {
      console.log('Chargement de la structureActuelle');
      fetchStructure();
    }
  }, [structureLocal, user, setStructureLocal]);


  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: name === 'montant' || name === 'factures' 
        ? Math.max(0, Number(value) || 0)
        : value
    }));
  }, []);
  
  const resetForm = useCallback(() => {
    setFormData(INITIAL_FORM_STATE);
    setMessage({ text: '', type: '' });
  }, []);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (structureLocal === null) {
      setMessage({
        text: 'Structure non trouvée.',
        type: 'error'
      });
      return;
    }

    if (!isFormValid) {
      setMessage({
        text: 'Veuillez corriger les erreurs du formulaire',
        type: 'error'
      });
      return;
    }

    setIsLoading(true);
    setMessage({ text: '', type: '' });

    try {
      // Construire le corps de la requête en JSON
      const body = {
        ...formData,
        id_structure: structureLocal?.id_structure
      };

      console.log('Données envoyées:', body);

      const response = await getReponseApi('/structures/create_one', 'POST', body);
      console.log('Reponse du serveur :', response);
      if (response?.message) {
       alert( `Enregistrement réussi - ${response.details.facturesGenerees} facture(s) générée(s)`);
         
        resetForm();
      }
    } catch (error) {
      setMessage({
        text: error.message || 'Une erreur est survenue lors de la création',
        type: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="import-container">
        <h2 className="import-title">
            <FontAwesomeIcon icon={faShoppingCart} />
            Ajout d'un produit/service
        </h2>

        <form onSubmit={handleSubmit}>
            <div className="form-grid">
                {/* Nom du produit */}
                <div className="form-field">
                    <label className="form-label">
                        Nom du produit/service *
                    </label>
                    <input
                        type="text"
                        name="nom_produit"
                        value={formData.nom_produit}
                        onChange={handleChange}
                        className="form-input"
                        placeholder="Ex: Thiebou dien"
                        required
                    />
                </div>

                {/* Montant */}
                <div className="form-field">
                    <label className="form-label">
                        Montant *
                    </label>
                    <input
                        type="number"
                        name="montant"
                        value={formData.montant}
                        onChange={handleChange}
                        className="form-input"
                        placeholder="Ex: 5000"
                        min="0"
                        required
                    />
                </div>

                {/* Nom du client */}
                <div className="form-field">
                    <label className="form-label">
                        Nom du client
                    </label>
                    <input
                        type="text"
                        name="nom_client"
                        value={formData.nom_client}
                        onChange={handleChange}
                        className="form-input"
                        placeholder="Ex: John Doe"
                    />
                </div>

                {/* Téléphone du client */}
                <div className="form-field">
                    <label className="form-label">
                        Téléphone client
                    </label>
                    <input
                        type="text"
                        name="tel_client"
                        value={formData.tel_client}
                        onChange={handleChange}
                        className="form-input"
                        placeholder="Ex: 771234567"
                        maxLength="9"
                    />
                </div>

                {/* Nombre de factures - pleine largeur */}
                <div className="form-field full-width">
                    <label className="form-label">
                        Nombre de factures
                    </label>
                    <input
                        type="number"
                        name="factures"
                        value={formData.factures}
                        onChange={handleChange}
                        className="form-input"
                        min="1"
                        placeholder="Ex: 1"
                    />
                </div>
            </div>

            <div className="button-container">
                <button
                    type="submit"
                    disabled={isLoading}
                    className="submit-button"
                >
                    {isLoading ? (
                        <>
                            <FontAwesomeIcon icon={faSpinner} spin />
                            Création...
                        </>
                    ) : (
                        <>
                            <FontAwesomeIcon icon={faSave} />
                            Créer facture
                        </>
                    )}
                </button>

                <button
                    type="button"
                    onClick={resetForm}
                    className="cancel-button"
                    disabled={isLoading}
                >
                    <FontAwesomeIcon icon={faEraser} />
                    Effacer
                </button>
            </div>
        </form>

        {message.text && (
            <div className={`message ${message.type}`}>
                {message.text}
            </div>
        )}
    </div>
  );
}

export default DonneesTab;
