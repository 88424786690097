import React, { useState, useEffect, useMemo } from 'react';
import { X, Save, ChevronLeft, ChevronRight,Search } from 'lucide-react';
import { getReponseApi } from '../apis/api_backend';
import '../styles/ArticleManagerModal.css';

const ArticleManagerModal = ({ isOpen, onClose, articles }) => {
  const [articleStats, setArticleStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 25;

  useEffect(() => {
    if (articles && articles.length > 0) {
      const stats = articles.map(article => ({
        nom_article: article.nom_article,
        montant: article.montant,
        id_structure: article.id_structure,
        quantite: article.qte,
        nombre: 0
      }));
      
      const uniqueStats = Array.from(
        new Map(stats.map(item => [item.nom_article, item])).values()
      );
      
      setArticleStats(uniqueStats);
    }
  }, [articles]);

  // Ajout d'un nouvel effet pour gérer la réinitialisation à l'ouverture
useEffect(() => {
  if (isOpen) {
    setArticleStats(prevStats => 
      prevStats.map(stat => ({
        ...stat,
        nombre: 0
      }))
    );
    setSearchTerm('');  // Réinitialise aussi la recherche
    setCurrentPage(1);  // Retourne à la première page
  }
}, [isOpen]);

  // Filtrage des articles
  const filteredArticles = useMemo(() => {
    if (!searchTerm.trim()) return articleStats;
    
    const searchTermLower = searchTerm.toLowerCase();
    return articleStats.filter(article => 
      article.nom_article.toLowerCase().includes(searchTermLower)
    );
  }, [articleStats, searchTerm]);

  // Réinitialiser la pagination quand le filtre change
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  // Calcul de la pagination sur les articles filtrés
  const totalPages = Math.ceil(filteredArticles.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentArticles = filteredArticles.slice(startIndex, endIndex);

  const totalArticles = filteredArticles.length;

  const handleNumberChange = (nom_article, value) => {
    const numValue = parseInt(value) || 0;
    setArticleStats(prevStats => 
      prevStats.map(stat => 
        stat.nom_article === nom_article 
          ? { ...stat, nombre: Math.max(0, numValue) }
          : stat
      )
    );
  };

  const getTotalMontant = useMemo(() => {
    return filteredArticles.reduce((total, article) => {
      return total + (article.quantite * parseFloat(article.montant));
    }, 0);
  }, [filteredArticles]);

  const handleSave = async () => {
    try {
      setLoading(true);
      setError('');
      
      const articlesAGenerer = articleStats.filter(stat => stat.nombre > 0);
     // s'il n'y a aucun article à générer, on sort de la fonction
      if (articlesAGenerer.length === 0) {
        alert('Aucun article à générer !');
        return;
      }
            
      for (const article of articlesAGenerer) {
        // Formatage des données selon la structure attendue par l'API

        const requestData = {
          id_structure: article.id_structure,
          nom_produit: article.nom_article,
          nom_client: 'Client',
          tel_client: '771234000',
          montant: parseFloat(article.montant) || 0,
          factures: parseInt(article.nombre)
        };
        
        const response = await getReponseApi('/structures/create_one', 'POST', requestData);
        if (!response?.message) {
          throw new Error(`Erreur lors de la génération de ${article.nom_article}`);
        }
      }

      alert('Articles générés avec succès !');
      onClose();
    } catch (err) {
      setError(err.message || 'Erreur lors de la génération des articles');
    } finally {
      setLoading(false);
    }
  };
  const formatMontant = (montant) => {
    const nombre = parseFloat(montant);
    return `${nombre.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}F`;
  };
  if (!isOpen) return null;

  return (
    <div className="am-modal-overlay">
      <div className="am-modal-content">
        <div className="am-modal-header">
          <h2>Gestion des Articles</h2>
          <button onClick={onClose} className="am-close-button">
            <X size={18} />
          </button>
          {error && (
            <div className="error-message mb-4 p-3 bg-red-100 text-red-700 rounded">
              {error}
            </div>
          )}
        </div>
        
        <div className="am-content">
                  {/* Nouvelle section de recherche et compteur */}
                  <div className="am-search-section">
            <div className="am-search-container">
              <Search size={20} className="am-search-icon" />
              <input
                type="text"
                placeholder="Rechercher un article..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="am-search-input"
              />
            </div>
            <div className="am-articles-count">
              {totalArticles} article{totalArticles > 1 ? 's' : ''} trouvé{totalArticles > 1 ? 's' : ''}
            </div>
          </div>
          <div className="am-table-container">
            <table className="am-table">
              <thead>
                <tr>
                  <th>Article/Service</th>
                  <th>Stock</th>
                  <th>Valeur</th>
                  <th>Ajouter</th>
                </tr>
              </thead>
              <tbody>
                {currentArticles.map((stat, index) => (
                  <tr key={index}>
                    <td>{stat.nom_article}: ({formatMontant(stat.montant)})</td>
                    <td>{stat.quantite}</td>
                    <td>{formatMontant(stat.quantite*stat.montant)}</td>
                    <td>
                      <input
                        type="number"
                        min="0"
                        value={stat.nombre}
                        onChange={(e) => handleNumberChange(stat.nom_article, e.target.value)}
                        className="am-number-input"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="am-total-section">
  <div className="am-total-amount">
    Total Stock: {formatMontant(getTotalMontant)}
  </div>
</div>
          <div className="am-pagination">
            <button 
              onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
              disabled={currentPage === 1}
              className="am-pagination-button"
            >
              <ChevronLeft size={20} />
            </button>
            
            <span className="am-pagination-info">
              Page {currentPage} sur {totalPages}
            </span>
            
            <button 
              onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
              disabled={currentPage === totalPages}
              className="am-pagination-button"
            >
              <ChevronRight size={20} />
            </button>
          </div>

          <div className="am-button-container">
            <button 
              onClick={onClose}
              className="am-cancel-button"
            >
              Annuler
            </button>
            <button
            onClick={handleSave}
            disabled={loading}
            className="am-save-button"
            title="Sauvegarder"
          >
            {loading ? (
              <span className="am-loading-spinner" />
            ) : (
              <Save size={24} />
            )}
          </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ArticleManagerModal;