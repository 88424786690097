import React, { useState, useEffect, useContext, useCallback } from 'react';
import { getReponseApi } from '../apis/api_backend';
import Header from '../Header';
import { PayEcoleContext } from '../../contexts/PayEcoleContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar, faCalendarAlt, faPhone, faUser,faNewspaper } from '@fortawesome/free-solid-svg-icons';
import EditFactureModal from './EditFactureModal';
import ArticleManagerModal from './ArticleManagerModal';
import PrepareFactureModal from './PrepareFactureModal';
import FactureSMSAlert from './FactureSMSAlert';
import CollapsibleFilters from './CollapsibleFilters';
import CollapsibleActions from './CollapsibleActions';
import '../styles/ListFactures.css';

const ListFactures = () => {
  // États existants conservés
  const [factures, setFactures] = useState([]);
  const [filteredFactures, setFilteredFactures] = useState([]);
  const [isArticleManagerOpen, setIsArticleManagerOpen] = useState(false);
  // Nouveaux states à ajouter en haut du composant ListFactures
const [isPrepareFactureOpen, setIsPrepareFactureOpen] = useState(false);
  const [selectedFactures, setSelectedFactures] = useState(new Set());
  const [isEnvoiSMSOpen, setIsEnvoiSMSOpen] = useState(false);
  const [filters, setFilters] = useState({
    nom_structure: '',
    date_debut_facture: '',
    date_fin_facture: '',
    libelle_etat: '',
    tel_client: '',
    nom_client: '',
    periode: '',  
    nom_classe: '',
    nom_classe_saisi: '' 
  });
  const [currentPage, setCurrentPage] = useState(1);
  const facturesPerPage = 15;
  const { user } = useContext(PayEcoleContext);

  const [articles, setArticles] = useState([]);

  // Nouveaux états pour le modal
  const [showModal, setShowModal] = useState(false);
  const [editingFacture, setEditingFacture] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Fonction existante fetchFactures conservée
  const fetchFactures = useCallback(async () => {
    console.log('Chargement des factures...');
    if (!user.id_structure) return;

    try {
      const body = { id_structure: user.id_structure };
      const response = await getReponseApi(`/factures/getall`, 'POST', body);
      if (response) {
        setFactures(response.data);
        console.log('Factures chargées:', response.data);
        setFilteredFactures(response.data);
        const uniqueArticles = getUniqueArticles(response.data);
        setArticles(uniqueArticles);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des factures:', error);
    }
  }, [user]);

  // Utilisation des fonctions existantes
  useEffect(() => {
    fetchFactures();
  }, [fetchFactures]);

  const nomClasses = articles.map(article => article.nom_article);
  const getUniqueArticles = (factures) => {
    // Créer un Map pour stocker les articles uniques avec leur dernière information de prix
    const articlesMap = new Map();
  
    // Premier passage pour compter les occurrences
    const countMap = new Map();
    factures.forEach(facture => {
      if (facture.nom_classe) {
        countMap.set(
          facture.nom_classe, 
          (countMap.get(facture.nom_classe) || 0) + 1
        );
      }
    });
  
    // Deuxième passage pour créer les objets avec toutes les informations
    factures.forEach(facture => {
      if (facture.nom_classe) {
        articlesMap.set(facture.nom_classe, {
          nom_article: facture.nom_classe,
          montant: facture.montant,
          id_structure: facture.id_structure,
          qte: countMap.get(facture.nom_classe)
        });
      }
    });
  
    return Array.from(articlesMap.values())
      .sort((a, b) => a.nom_article.localeCompare(b.nom_article, 'fr', { sensitivity: 'base' }));
  };
  
  const getUniquePeriodes = (factures) => {
    const periodes = new Set(factures.map(facture => `${facture.nmois}-${facture.nannee}`));
    return Array.from(periodes)
      .sort((a, b) => {
        const [moisA, anneeA] = a.split('-').map(Number);
        const [moisB, anneeB] = b.split('-').map(Number);
        
        if (anneeA !== anneeB) return anneeB - anneeA;
        return moisB - moisA;
      });
  };


  const handleFactureSelection = (factureId) => {
    setSelectedFactures(prev => {
      const newSelection = new Set(prev);
      if (newSelection.has(factureId)) {
        newSelection.delete(factureId);
      } else {
        newSelection.add(factureId);
      }
      console.log('selectedFactures', selectedFactures)
      return newSelection;
    });
  };

  const GetUniqueClients = (factures) => {
    // Créer un Map pour regrouper les factures par client (clé: tel_client)
    const clientsMap = new Map();
  
    // Regrouper les factures par client
    factures.forEach(facture => {
      const clientKey = facture.tel_client;
      
      if (!clientsMap.has(clientKey)) {
        // Premier ajout du client
        clientsMap.set(clientKey, {
          nom_structure: facture.nom_structure,
          periode: `${facture.nmois}-${facture.nannee}`,
          montant: parseFloat(facture.montant),
          num_facture: facture.num_facture,
          tel_client: facture.tel_client,
          nmois: facture.nmois,
          nannee: facture.nannee,
          nom_client: facture.nom_client
        });
      } else {
        // Client existant : mettre à jour les données
        const existingClient = clientsMap.get(clientKey);
        
        // Ajouter le nouveau numéro de facture
        existingClient.num_facture = `${existingClient.num_facture}@${facture.num_facture}`;
        
        // Ajouter le montant
        existingClient.montant += parseFloat(facture.montant);
        
        // Mettre à jour la période si nécessaire (prendre la plus récente)
        const existingDate = new Date(existingClient.periode.split('-')[1], existingClient.periode.split('-')[0] - 1);
        const newDate = new Date(facture.nannee, facture.nmois - 1);
        
        if (newDate > existingDate) {
          existingClient.periode = `${facture.nmois}-${facture.nannee}`;
        }
      }
    });
  
    // Convertir le Map en tableau et trier par nom du client
    const uniqueClients = Array.from(clientsMap.values())
      .sort((a, b) => a.nom_client.localeCompare(b.nom_client, 'fr', { sensitivity: 'base' }));
  
    return uniqueClients;
  };
  
  // Nouvelles fonctions pour la gestion des factures
  const handleEditClick = (facture) => {
    setEditingFacture(facture);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingFacture(null);
  };

    // Fonction de mise à jour modifiée
    const handleUpdateFacture = async (factureId, updatedData) => {
      try {
        // Rafraîchir les données
        await fetchFactures();
        alert('Facture mise à jour avec succès');
      } catch (error) {
        console.error('Erreur lors du rafraîchissement des données:', error);
      }
    };

  // Fonction de suppression modifiée
  const handleDeleteFacture = async (factureId) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer cette facture ?')) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await getReponseApi(`/factures/${factureId}`, 'DELETE');
      if (response.success) {
        // Rafraîchir les données après la suppression
        await fetchFactures();
        alert('Facture supprimée avec succès');
      } else {
        throw new Error(response.error || 'Erreur lors de la suppression');
      }
    } catch (error) {
      console.error('Erreur lors de la suppression:', error);
      alert(error.message || 'Erreur lors de la suppression de la facture');
    } finally {
      setIsLoading(false);
    }
  };


  const formatPeriode = (mois, annee) => {
    // Validation des entrées
    if (!mois || !annee) return 'Période non définie';
    
    // Conversion en nombres pour validation
    const moisNum = parseInt(mois);
    const anneeNum = parseInt(annee);
    
    // Validation des valeurs
    if (isNaN(moisNum) || isNaN(anneeNum) || 
        moisNum < 1 || moisNum > 12 || 
        anneeNum < 2000 || anneeNum > 2100) {
      return 'Période invalide';
    }
  
    const moisNoms = {
      1: 'Jan', 2: 'Fév', 3: 'Mar', 4: 'Avr',
      5: 'Mai', 6: 'Juin', 7: 'Juil', 8: 'Aoû',
      9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Déc'
    };
  
    return `${moisNoms[moisNum]}-${anneeNum}`;
  };
  
  
  // Gérer les changements dans les filtres
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value
      

    }));
  };

  // Appliquer le filtrage lorsque les filtres ou les factures changent
  useEffect(() => {
    let result = factures;
    
    if (filters.nom_structure) result = result.filter(facture => facture.nom_structure.includes(filters.nom_structure));
    
    // Filtre par select
    if (filters.nom_classe) result = result.filter(facture => facture.nom_classe === filters.nom_classe);
    
    // Filtre par saisie avec log
    if (filters.nom_classe_saisi) {
      console.log('Tentative de filtrage par nom_classe_saisi');
      result = result.filter(facture => {
        const matchFound = facture.nom_classe && 
                         facture.nom_classe.toLowerCase().includes(filters.nom_classe_saisi.toLowerCase());
       
        return matchFound;
      });
    }

    if (filters.periode) {
      const [moisFiltre, anneeFiltre] = filters.periode.split('-').map(Number);
      result = result.filter(facture => 
        facture.nmois === moisFiltre && facture.nannee === anneeFiltre
      );
    }
  
    if (filters.libelle_etat) result = result.filter(facture => facture.libelle_etat === filters.libelle_etat);
    if (filters.tel_client) result = result.filter(facture => facture.tel_client.includes(filters.tel_client));
    if (filters.nom_client) result = result.filter(facture => facture.nom_client.toLowerCase().includes(filters.nom_client.toLowerCase()));
    
    
    setFilteredFactures(result);
    setCurrentPage(1);
  }, [filters, factures]);

  // Pagination
  const indexOfLastFacture = currentPage * facturesPerPage;
  const indexOfFirstFacture = indexOfLastFacture - facturesPerPage;
  const currentFactures = filteredFactures.slice(indexOfFirstFacture, indexOfLastFacture);

  const totalPages = Math.ceil(filteredFactures.length / facturesPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  // Calculer les statistiques des factures
  const totalFactures = filteredFactures.length;
  const totalMontantPayees = filteredFactures.filter(f => f.libelle_etat === 'PAYEE').reduce((acc, f) => acc + parseFloat(f.montant), 0);
  const totalMontantImpayees = filteredFactures.filter(f => f.libelle_etat === 'IMPAYEE').reduce((acc, f) => acc + parseFloat(f.montant), 0);

  return (
    <div className="list-factures">
      <Header />

      {/* Filtres pour les factures */}
      <CollapsibleFilters
          filters={filters}
          handleFilterChange={handleFilterChange}
          user={user}
          nomClasses={nomClasses}
          getUniquePeriodes={getUniquePeriodes}
          factures={factures}
        />

      {/* Statistiques des factures */}
      <div className="factures-stats">
        <p>Total factures: {totalFactures}</p>
        <p>Montant total des factures payées: {totalMontantPayees} FCFA</p>
        <p>Montant total des factures impayées: {totalMontantImpayees} FCFA</p>
      </div>
      <CollapsibleActions
          user={user}
          
          selectedFactures={selectedFactures}
          setIsArticleManagerOpen={setIsArticleManagerOpen}
          setIsPrepareFactureOpen={setIsPrepareFactureOpen}
          setIsEnvoiSMSOpen={setIsEnvoiSMSOpen}
          handleDeleteFactures={async () => {
            if (window.confirm(`Êtes-vous sûr de vouloir supprimer ${selectedFactures.size} facture(s) ?`)) {
              setIsLoading(true);
              try {
                for (const factureId of selectedFactures) {
                  await getReponseApi(`/factures/${factureId}`, 'DELETE');
                }
                setSelectedFactures(new Set());
                await fetchFactures();
                alert('Factures supprimées avec succès');
              } catch (error) {
                console.error('Erreur lors de la suppression groupée:', error);
                alert('Erreur lors de la suppression des factures');
              } finally {
                setIsLoading(false);
              }
            }
          }}
          isLoading={isLoading}
        />
      {/* Pagination */}
      <div className="pagination-controls">
        <button onClick={handleFirstPage} disabled={currentPage === 1}>Début</button>
        <button onClick={handlePreviousPage} disabled={currentPage === 1}>Précédent</button>
        <span>Page {currentPage} sur {totalPages}</span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>Suivant</button>
        <button onClick={handleLastPage} disabled={currentPage === totalPages}>Fin</button>
      </div>

    {/* Liste des factures modifiée */}
    <div className="factures-container">
        {currentFactures.map(facture => (
          <div 
            key={facture.id_facture} 
            className="facture-card"
            onClick={(e) => {
              // Éviter d'ouvrir le modal si on clique sur les boutons
              if (!e.target.closest('.facture-actions')) {
                handleEditClick(facture);
              }
            }}
            style={{ cursor: 'pointer' }}
          >
            {/* Indicateur visuel pour montrer que la carte est cliquable */}
            <div className="hover-overlay" />
            
            <div className="facture-header">
                <h3>{facture.nom_classe}</h3>
                <input
                  type="checkbox"
                  checked={selectedFactures.has(facture.id_facture)}
                  onChange={() => handleFactureSelection(facture.id_facture)}
                  onClick={(e) => e.stopPropagation()}
                  className="facture-checkbox"
                />
              </div>
            <p><FontAwesomeIcon icon={faNewspaper} /> N° Facture: {facture.num_facture}</p>
            <p><FontAwesomeIcon icon={faCalendarAlt} /> Période: {formatPeriode(facture.nmois, facture.nannee)}</p>
            <p><FontAwesomeIcon icon={faUser} /> Client: {facture.nom_client}</p>
            <p><FontAwesomeIcon icon={faPhone} /> Téléphone: {facture.tel_client}</p>
            <p><FontAwesomeIcon icon={faFileInvoiceDollar} /> Montant: {facture.montant} FCFA</p>
            <p>
              <span className={`etat-badge ${facture.libelle_etat.toLowerCase()}`}>
                {facture.libelle_etat}
              </span>
            </p>
            
            {/* Les boutons d'action dans un conteneur séparé pour éviter la propagation du clic */}
            <div className="facture-actions" onClick={e => e.stopPropagation()}>
              <button 
                className="facture-card-edit" 
                onClick={() => handleEditClick(facture)}
                disabled={isLoading}
              >
                Éditer la facture
              </button>
              <button 
                className="facture-card-delete" 
                onClick={() => handleDeleteFacture(facture.id_facture)}
                disabled={facture.id_etat !== 1 || isLoading}
              >
                Supprimer la facture
              </button>
            </div>
          </div>
        ))}
      </div>          
         {/* Modal d'édition */}
       <EditFactureModal
        facture={editingFacture}
        isOpen={showModal}
        onClose={handleCloseModal}
        onUpdate={handleUpdateFacture}
        isLoading={isLoading}
        />
     <ArticleManagerModal
        isOpen={isArticleManagerOpen}
        onClose={() => setIsArticleManagerOpen(false)}
        articles={articles}
        structure={user}
      />
      <FactureSMSAlert
          isOpen={isEnvoiSMSOpen}
          onClose={() => setIsEnvoiSMSOpen(false)}
          factures={GetUniqueClients(
            selectedFactures.size > 0 
              ? factures.filter(f => selectedFactures.has(f.id_facture))
              : filteredFactures
          )}
          structure={user}
        />
        <PrepareFactureModal
  isOpen={isPrepareFactureOpen}
  onClose={() => setIsPrepareFactureOpen(false)}
  
  selectedFactures={selectedFactures}
  onSuccess={fetchFactures}
/>
    </div>
  );
};

export default ListFactures;