import React, { useState, useEffect, useContext } from 'react';
import { PayEcoleContext } from '../../../contexts/PayEcoleContext';
import SignaturePad from 'signature_pad';
import { PDFViewer } from '@react-pdf/renderer';
import ConventionPDF from './ConventionPDF';
import './styles/ConventionForm.css';

const ConventionForm = () => {
  const { structure } = useContext(PayEcoleContext);

  // États
  const [convention, setConvention] = useState({
    numero_convention: `CONV-${Date.now()}`,
    representant: '',
    fonction_representant: '',
    type_reversement: '',
    signature_data: null,
    cachet_data: null,
    est_ratifiee: false
  });

  const [signaturePad, setSignaturePad] = useState(null);
  const [previewMode, setPreviewMode] = useState(false);

  // Initialisation du pad de signature
  useEffect(() => {
    const canvas = document.getElementById('signaturePad');
    if (canvas) {
      const pad = new SignaturePad(canvas, {
        penColor: '#3498DB',
        backgroundColor: 'rgb(255, 255, 255)'
      });
      setSignaturePad(pad);

      // Ajuster la taille du canvas lors du redimensionnement
      const resizeCanvas = () => {
        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);
        pad.clear();
      };

      window.addEventListener("resize", resizeCanvas);
      resizeCanvas();

      // Nettoyage
      return () => {
        window.removeEventListener("resize", resizeCanvas);
      };
    }
  }, []);

  // Gestionnaire de changement des champs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setConvention(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Gestionnaire de soumission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!signaturePad || signaturePad.isEmpty()) {
      alert('Veuillez signer la convention');
      return;
    }

    try {
      const formData = {
        ...convention,
        signature_data: signaturePad.toDataURL(),
        id_structure: structure.id
      };

      console.log('Convention à sauvegarder:', formData);
      // Ici, ajoutez votre logique de sauvegarde API
      
      alert('Convention enregistrée avec succès!');
    } catch (error) {
      console.error('Erreur lors de la sauvegarde:', error);
      alert('Erreur lors de la sauvegarde de la convention');
    }
  };

  // Gestion du cachet
  const handleCachetUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setConvention(prev => ({
          ...prev,
          cachet_data: event.target.result
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="convention-form-container">
      <form onSubmit={handleSubmit} className="convention-form">
        <div className="form-header">
          <h2>Convention de Services</h2>
          <span className="convention-number">N° {convention.numero_convention}</span>
        </div>

        {/* Informations de la structure */}
        <div className="form-section">
          <h3>Information de la Structure</h3>
          <div className="form-row">
            <div className="form-group">
              <label>Nom de la structure</label>
              <input
                type="text"
                value={structure?.nom_structure || ''}
                disabled
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Adresse</label>
              <input
                type="text"
                value={structure?.adresse || ''}
                disabled
                className="form-control"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                value={structure?.email || ''}
                disabled
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Téléphone</label>
              <input
                type="tel"
                value={structure?.mobile_om || ''}
                disabled
                className="form-control"
              />
            </div>
          </div>
        </div>

        {/* Détails de la convention */}
        <div className="form-section">
          <h3>Détails de la Convention</h3>
          <div className="form-row">
            <div className="form-group">
              <label>Représentant *</label>
              <input
                type="text"
                name="representant"
                value={convention.representant}
                onChange={handleChange}
                required
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Fonction du représentant *</label>
              <input
                type="text"
                name="fonction_representant"
                value={convention.fonction_representant}
                onChange={handleChange}
                required
                className="form-control"
              />
            </div>
          </div>
          <div className="form-group">
            <label>Type de reversement *</label>
            <select
              name="type_reversement"
              value={convention.type_reversement}
              onChange={handleChange}
              required
              className="form-control"
            >
              <option value="">Sélectionner un type</option>
              <option value="R1">R1 : Reversement automatique</option>
              <option value="R2">R2 : Reversement toutes les 24 heures</option>
              <option value="R3">R3 : Reversement hebdomadaire</option>
              <option value="R4">R4 : Reversement mensuel</option>
            </select>
          </div>
        </div>

        {/* Section signature et cachet */}
        <div className="signature-cachet-container">
          {/* Section signature */}
          <div className="signature-section">
            <h3>Signature</h3>
            <canvas 
              id="signaturePad" 
              className="signature-pad"
            />
            <button 
              type="button" 
              onClick={() => signaturePad?.clear()}
              className="btn btn-secondary"
            >
              Effacer la signature
            </button>
          </div>

          {/* Section cachet */}
          <div className="cachet-section">
            <h3>Cachet de l'établissement</h3>
            <input
              type="file"
              accept="image/*"
              onChange={handleCachetUpload}
              className="form-control"
            />
            {convention.cachet_data && (
              <img 
                src={convention.cachet_data}
                alt="Aperçu du cachet" 
                className="cachet-preview"
              />
            )}
          </div>
        </div>

        {/* Actions */}
        <div className="form-actions">
          <button
            type="button"
            onClick={() => setPreviewMode(!previewMode)}
            className="btn btn-secondary"
          >
            {previewMode ? 'Fermer l\'aperçu' : 'Aperçu PDF'}
          </button>
          <button type="submit" className="btn btn-primary">
            Valider la Convention
          </button>
          <button
            type="button"
            onClick={() => window.history.back()} // ou utilisez la navigation de votre routeur
            className="btn-closeform"
          >
            <i className="bi bi-arrow-left"></i> Retour
          </button>
        </div>
      </form>

      {/* Aperçu PDF */}
      {previewMode && (
        <div className="pdf-preview">
          <button 
            className="pdf-preview-close"
            onClick={() => setPreviewMode(false)}
          >
            ×
          </button>
          <PDFViewer 
            className="pdf-viewer"
            showToolbar={true}
          >
            <ConventionPDF
              convention={convention}
              signatureData={signaturePad?.toDataURL()}
              cachetData={convention.cachet_data}
              structure={structure}
            />
          </PDFViewer>
        </div>
      )}
    </div>
  );
};

export default ConventionForm;