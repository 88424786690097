import React, { useState, useEffect, useCallback } from 'react';
import { getReponseApi } from '../apis/api_backend';
import '../styles/ProcessingStep.css';  // Import des styles

const MaxitIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 2H7C5.89543 2 5 2.89543 5 4V20C5 21.1046 5.89543 22 7 22H17C18.1046 22 19 21.1046 19 20V4C19 2.89543 18.1046 2 17 2Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 18H12.01" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <line x1="8" y1="6" x2="16" y2="6" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
    </svg>
  );
  
  const OrangeMoneyIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 2H8.5C7.11929 2 6 3.11929 6 4.5V19.5C6 20.8807 7.11929 22 8.5 22H15.5C16.8807 22 18 20.8807 18 19.5V4.5C18 3.11929 16.8807 2 15.5 2Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 19H12.01" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9 5H15" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
      <path d="M11 3H13" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
    </svg>
  );

const ProcessingStep = ({ factures, onClose, paymentId }) => {
  const [timeLeft, setTimeLeft] = useState(90);
  const [qrData, setQrData] = useState(null);
  const [isPolling, setIsPolling] = useState(false);
  const [initialWait, setInitialWait] = useState(10);

  const checkQrStatus = useCallback(async () => {
    try {
      const body = {
        id_demande: paymentId,
        nom_structure: factures[0].nom_structure,
        numfacture: factures[0].num_facture
      };
      
      const response = await getReponseApi('/factures/get_qr_onedemande', 'POST', body);
      
      if (response && response[0]?.code_json) {
        const parsedData = JSON.parse(response[0].code_json);
        if (parsedData.QRCODE) {
          setQrData(parsedData);
          setIsPolling(false);
        }
      }
    } catch (err) {
      console.error('Erreur lors de la vérification:', err);
    }
  }, [factures, paymentId]);

  useEffect(() => {
    if (initialWait > 0) {
      const timer = setTimeout(() => setInitialWait(prev => prev - 1), 500);
      console.log('Initial wait:', paymentId, factures[0].nom_structure, factures[0].num_facture);
      return () => clearTimeout(timer);
    } else {
      setIsPolling(true);
    }
  }, [initialWait, paymentId, factures]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            onClose();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [timeLeft, onClose]);

  useEffect(() => {
    let pollInterval;
    if (isPolling) {
      checkQrStatus();
      pollInterval = setInterval(checkQrStatus, 3000);
    }
    return () => clearInterval(pollInterval);
  }, [isPolling, checkQrStatus]);

  const renderLoadingState = () => (
    <>
      <div className="processing-animation">
        <div className="spinner" />
      </div>
      
      <h3>Traitement en cours...</h3>
      
      <div className="processing-details">
        <p>Votre demande de paiement est en cours de traitement.</p>
        <p>Veuillez vérifier votre téléphone.</p>
        <div className="timer">
          {initialWait > 0 
            ? `Vérification dans ${initialWait} secondes...`
            : `Cette fenêtre se fermera dans ${timeLeft} secondes`
          }
        </div>
      </div>
    </>
  );

  const renderPaymentMethods = () => (
    <div className="payment-methods">
      <h3>Choisissez votre méthode de paiement</h3>
      
      <div className="qr-code-container">
        <div className="qr-code-wrapper">
          <img 
            src={`data:image/png;base64,${qrData.QRCODE}`}
            alt="QR Code de paiement"
            className="qr-code"
          />
        </div>
        <p>Scannez le QR code pour payer</p>
      </div>
      
      <div className="payment-links">
        <p>Ou bien, payez avec l'application:</p>
        <a href={qrData.MAXIT} target="_blank" rel="noopener noreferrer" className="payment-button maxit">
          <MaxitIcon />
          <span>cliquer pour MAXIT</span>
          <div className="button-glow"></div>
        </a>
        <a href={qrData.OM} target="_blank" rel="noopener noreferrer" className="payment-button om">
          <OrangeMoneyIcon />
          <span>Cliquer pour OM</span>
          <div className="button-glow"></div>
        </a>
      </div>
      
      <div className="timer">
        Cette fenêtre se fermera dans {timeLeft} secondes
      </div>
    </div>
  );
      

  return (
    <div className="step-content processing">
      {!qrData ? renderLoadingState() : renderPaymentMethods()}
    </div>
  );
};

export default ProcessingStep;