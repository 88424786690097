import React, { useEffect } from 'react';
import { X } from 'lucide-react';
import ReactDOM from 'react-dom';
import '../styles/Modal.css';

const Modal = ({ onClose, title, children }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') onClose();
    };
    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  // Utiliser un portail pour monter le modal directement sur le body
  return ReactDOM.createPortal(
    <div 
      className="edit-modal-overlay"
      onClick={(e) => e.target === e.currentTarget && onClose()}
    >
      <div className="edit-modal-content">
        <button className="edit-modal-close" onClick={onClose}>
          <X size={18} />
        </button>
        
        <div className="edit-modal-header">
          <h2 className="edit-modal-title">
            {title || "Modifier la structure"}
          </h2>
        </div>

        <div className="edit-modal-body">
          {children}
        </div>
      </div>
    </div>,
    document.body // Monter le modal directement sur le body
  );
};

export default Modal;