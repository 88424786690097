// src/AppContent.js
import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import HomePage from './components/Home';
import Login from './components/Login';
import Compte from './components/Admin/MonCompte';
import About from './components/About';
import ConventionForm from './components/Admin/Convention/ConventionForm';
import PayeDirect from './components/Admin/PayeDirect';
import ConfirmationPayment from './components/Admin/ConfirmationPayment';
import Listfactures from './components/Admin/ListFactures';
import PayFacture from './components/Admin/jepay';
import PopPayement from './components/Admin/PopupPayement';
import PopTicketRecu from './components/Admin/TicketRecu';
import SuccessPayment from './components/SuccessPayment';
import FloatingButton from './components/Admin/FloatingButton';
import NewStructure from './components/Admin/NewStructure';
import EditStructure from './components/Admin/EditStructure';
import UneStructure from './components/Admin/UneStructure';

const AppContent = () => {
  const location = useLocation();

  return (
    <>
      <Routes>
        <Route path="/facture/:invoiceNumber" element={<PayeDirect />} />
        <Route path="/confirmation/:num_facture/:nom_structure" element={<ConfirmationPayment />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/apropos" element={<About />} />
        <Route path="/inscription" element={<NewStructure />} />
        <Route path="/jepaye" element={<PayFacture />} />
        <Route path="/recu" element={<PopTicketRecu />} />
        <Route path="/popup" element={<PopPayement />} />
        <Route path="/convention" element={<ConventionForm />} />
        <Route path="/success/:num_facture/:ref_destinate" element={<SuccessPayment />} />
    
        {/* Routes protégées */}
        <Route element={<ProtectedRoute />}>
          <Route path="/compte" element={<Compte />} />
          <Route path="/mastructure" element={<UneStructure />} />
          <Route path="/edit_structure" element={<EditStructure />} />
          <Route path="/paiements" element={<Listfactures />} />
        </Route>
      </Routes>
      {location.pathname === '/' && <FloatingButton />}
    </>
  );
};

export default AppContent;