import React, { useState } from 'react';
import { getReponseApi } from '../apis/api_backend';
import { X } from 'lucide-react';

const PrepareFactureModal = ({ isOpen, onClose, selectedFactures, onSuccess }) => {
  const [formData, setFormData] = useState({
    nom_client_payeur: '',
    tel_client: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError(''); // Réinitialiser l'erreur lors de la modification
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      // Mise à jour séquentielle de chaque facture
      for (const factureId of selectedFactures) {
        const response = await getReponseApi(
          `/factures/updateFacture/${factureId}`,
          'PUT',
          {
            nom_client_payeur: formData.nom_client_payeur,
            tel_client: formData.tel_client
          }
        );

        if (!response || !response.id_facture) {
          throw new Error(`Échec de la mise à jour de la facture ${factureId}`);
        }
      }

      onSuccess(); // Rafraîchir les données
      onClose(); // Fermer le modal
      
    } catch (err) {
      console.error('Erreur lors de la mise à jour:', err);
      setError(err.message || 'Une erreur est survenue lors de la mise à jour des factures');
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlayfk">
      <div className="modal-contentfk">
        <div className="modalef-header">
        <h2>
            {selectedFactures.size === 1 
              ? "1 facture à envoyer"
              : `${selectedFactures.size} factures à envoyer`}
          </h2>
          <div className="modal-line">
          <p> Saisir le nom et le numéro du client pour regrouper les factures.</p>
          </div>
          
          <button onClick={onClose} className="btn-close">
            <X size={18} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="modal-form">
          {error && <div className="error-message">{error}</div>}
          <div className="form-group">
            <label htmlFor="nom_client_payeur">Nom du client</label>
            <input
              type="text"
              id="nom_client_payeur"
              name="nom_client_payeur"
              value={formData.nom_client_payeur}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="tel_client">Téléphone client</label>
            <input
              type="text"
              id="tel_client"
              name="tel_client"
              value={formData.tel_client}
              onChange={handleChange}
              pattern="\d{9}"
              title="Le numéro doit contenir exactement 9 chiffres"
              required
            />
          </div>

          <div className="modal-actions">
            <button 
              type="button" 
              onClick={onClose}
              className="cancel-button"
              disabled={isLoading}
            >
              Annuler
            </button>
            <button 
              type="submit" 
              className="submit-button"
              disabled={isLoading}
            >
              {isLoading ? 'Mise à jour...' : 'Valider'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PrepareFactureModal;