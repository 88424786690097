import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Calendar, Phone, Building, FileText, GraduationCap, Printer, CheckCircle } from 'lucide-react';
import '../styles/ticketRecu.css';

const TicketRecu = ({ facture, onClose }) => {
  const formatDate = (date) => {
    return new Intl.DateTimeFormat('fr-FR', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    }).format(new Date(date)).toUpperCase();
  };

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  const handleBackdropClick = (e) => {
    // Vérifier si le clic est sur le backdrop (fond sombre)
    if (e.target.className.includes('receipt-popup')) {
      onClose();
    }
  };

  const handleCloseClick = (e) => {
    e.stopPropagation(); // Empêcher la propagation vers le backdrop
    onClose();
  };

  const handleContentClick = (e) => {
    // Empêcher la propagation du clic depuis le contenu vers le backdrop
    e.stopPropagation();
  };

  return (
    <motion.div 
      className="receipt-popup"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={handleBackdropClick}
    >
      <motion.div 
        className="receipt-container"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 20, opacity: 0 }}
        onClick={handleContentClick}
      >
        <button 
          type="button"
          className="close-button" 
          onClick={handleCloseClick}
        >
          ×
        </button>

        <div className="receipt-header">
          <div className="receipt-logo">
            <img src={facture.logo} alt="Logo" />
          </div>
          <div className="receipt-number">
            N° {facture.numrecu}
          </div>
          <div className="receipt-date">
            <Calendar size={16} />
            Payée le {formatDate(facture.tms_update)}
          </div>
        </div>

        <div className="receipt-body">
          {/* Informations client */}
          <div className="receipt-section">
            <h3 className="section-title">
              <span>Informations Client</span>
            </h3>
            <div className="info-grid">
              <div className="info-item">
                <div className="info-label">
                  <Phone size={16} />
                  Téléphone
                </div>
                <div className="info-value">{facture.tel_client}</div>
              </div>
              <div className="info-item">
                <div className="info-label">Nom</div>
                <div className="info-value">{facture.nom_client}</div>
              </div>
            </div>
          </div>

          {/* Détails paiement */}
          <div className="receipt-section">
            <h3 className="section-title">
              <span>Détails Paiement</span>
            </h3>
            <div className="info-grid">
              <div className="info-item">
                <div className="info-label">
                  <Building size={16} />
                  Structure
                </div>
                <div className="info-value">{facture.nom_structure}</div>
              </div>
              <div className="info-item">
                <div className="info-label">
                  <FileText size={16} />
                  N° Facture
                </div>
                <div className="info-value">{facture.num_facture}</div>
              </div>
              <div className="info-item">
                <div className="info-label">
                  <GraduationCap size={16} />
                  Désignation
                </div>
                <div className="info-value">{facture.nom_classe}</div>
              </div>
            </div>
          </div>

          {/* Montant et statut */}
          <div className="receipt-total">
            <div className="total-row">
              <span className="total-label">Montant Total</span>
              <span className="total-amount">{facture.montant} F</span>
            </div>
            <div className="receipt-status">
              <CheckCircle size={16} />
              {facture.libelle_etat}
            </div>
          </div>

          {/* Bouton d'impression */}
          <button className="print-button" onClick={() => window.print()}>
            <Printer size={18} />
            Imprimer le reçu
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default TicketRecu;