import React, { useState, useEffect} from 'react';
import Modal from './Modal';
import { Wallet, ArrowDownToLine, Banknote } from 'lucide-react';
import { getReponseApi } from '../apis/api_backend';
import '../styles/EtatFinStructure.css';

const EtatFinStructure = ( {structure }) => {
  const [localStructure, setLocalStructure] = useState(null); 
  const [showReversementModal, setShowReversementModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [financialData, setFinancialData] = useState({
    totalPaye: 0,
    totalReverse: 0,
    soldeCompte: 0
  });
  const [loading, setLoading] = useState(true);
  const [disableReversementButton, setDisableReversementButton] = useState(false);


  useEffect(() => {
    const fetchStructure = async () => {
      try {
        console.log('Structure reçue dans EditFinStructure:', structure);
        if (structure) {
          setLocalStructure(structure);
          if (localStructure?.id_structure) {
            // Remplacez ce chemin par votre vrai endpoint API
            await fetchFinancialData(localStructure);
          }
         
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des informations de la structure:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStructure();
  }, [structure, localStructure]);

  const fetchFinancialData = async (currentStructure) => {
    try {
      if (currentStructure?.id_structure) {
        setLoading(true);
        // Remplacez ce chemin par votre vrai endpoint API
        const response = await getReponseApi(`/structures/solde/${currentStructure.id_structure}`, 'GET');
        console.log("solde de la structure : ", response);
        if (response) {
          setFinancialData({
            totalPaye: response.total_credit || 0,
            totalReverse: response.total_debit || 0,
            soldeCompte: response.solde || 0
          });
          setDisableReversementButton(response.solde <= 0);
        }
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des données financières:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour récupérer la structure depuis les cookies

  const handleReversement = async () => {
    if (!phoneNumber) {
      alert('Veuillez saisir un numéro de téléphone');
      return;
    }

    try {
      // Préparation des données pour la demande de reversement
      const reversementData = {
        nom_app: 'ICELABOSOFT',
        nom_structure: structure.nom_structure,
        numfacture: 'REVERSMT',
        numtelclient: phoneNumber,
        montant: financialData.soldeCompte,
        frais: 0,
        avec_infosms: false,
        others_num_facture: ''
      };

      // Envoi de la demande de reversement
      const reversementResponse = await getReponseApi('/factures/send_cashin', 'POST', reversementData);

      if (reversementResponse.error) {
        throw new Error(reversementResponse.error);
      }

      // Si le reversement est réussi, on met à jour les données financières
      await fetchFinancialData(structure);
      setDisableReversementButton(financialData.soldeCompte <= 0);

      // Fermeture du modal et réinitialisation
      setShowReversementModal(false);
      setPhoneNumber('');
      alert('Demande de reversement effectuée avec succès');
    } catch (error) {
      console.error('Erreur lors du reversement:', error);
      alert(error.message || 'Une erreur est survenue lors de la création de la demande de reversement.');
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  return (
    <div className="finance-container">
      <div className="finance-cards">
        <div className="finance-card">
          <div className="card-header">
            <h3 className="card-title">Montant Total Reçu</h3>
            <Wallet className="card-icon" />
          </div>
          <div className="card-content">
            <div className="amount">{financialData.totalPaye.toLocaleString()} FCFA</div>
          </div>
        </div>

        <div className="finance-card">
          <div className="card-header">
            <h3 className="card-title">Montant Total Reversé</h3>
            <ArrowDownToLine className="card-icon" />
          </div>
          <div className="card-content">
            <div className="amount">{financialData.totalReverse.toLocaleString()} FCFA</div>
          </div>
        </div>

        <div className="finance-card">
          <div className="card-header">
            <h3 className="card-title">Solde en Compte</h3>
            <Banknote className="card-icon" />
          </div>
          <div className="card-content">
            <div className="amount">{financialData.soldeCompte.toLocaleString()} FCFA</div>
          </div>
        </div>
      </div>

      <div className="action-container">
        <button
          className="reversement-button"
          onClick={() => setShowReversementModal(true)}
          disabled={disableReversementButton}
        >
          Effectuer un reversement
        </button>
      </div>

      {showReversementModal && (
        <Modal title="Nouveau Reversement" onClose={() => setShowReversementModal(false)}>
          <div className="modal-content">
            <div className="solde-info">
              <h3>Solde à reverser</h3>
              <p className="solde-amount">{financialData.soldeCompte.toLocaleString()} FCFA</p>
            </div>

            <div className="input-group">
              <label>Numéro de téléphone</label>
              <input
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Entrez le numéro de téléphone"
                className="phone-input"
              />
            </div>

            <div className="modal-actions">
              <button className="cancel-button" onClick={() => setShowReversementModal(false)}>
                Annuler
              </button>
              <button className="confirm-button" onClick={handleReversement}>
                Confirmer le reversement
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default EtatFinStructure;