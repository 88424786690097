// api.js

export async function envoyerRequeteApi(pAppName, pRequete, additionalParam) {
    let sqlQuery = construireRequeteSql(pAppName, pRequete, additionalParam);
    let xml;

    try {
        xml = construireXml(pAppName, sqlQuery); 
    } catch (error) {
        console.error("Error constructing SQL query:", error);
        return null;
    }

    try {
        const response = await fetch('https://psqlv1-a596b59b1413.herokuapp.com/api/execute_requete_from_xml/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/xml',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type'
            },
            body: xml,
            mode: 'cors', // Activer explicitement le mode CORS
            credentials: 'same-origin' // Gérer les cookies si nécessaire
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Network response was not ok: ${response.status} ${errorText}`);
        }

        const data = await response.json();        
        if (Array.isArray(data) && data.length > 0) {
            return data;
        }
        else if (data && typeof data === 'object' && Object.keys(data).length > 0) {
            return data;
        } else {
            return null;
        }
    } catch (error) {
        console.error('Erreur lors de la requête API:', error);
        throw error; // Propager l'erreur pour une meilleure gestion
    }
}

function construireRequeteSql(pSQL_Request, additionalParam) {
    let sqlQuery = pSQL_Request;
    if (additionalParam !== "") {
        sqlQuery += additionalParam;
    }
    return sqlQuery;
}

function construireXml(pAppName, requeteSql) {
    let sql_text = requeteSql.replace(/\n/g, '');
    return `<?xml version="1.0" encoding="UTF-8"?>
        <requete>
            <application>${pAppName}</application>
            <requete_sql>${sql_text}</requete_sql>
            <mode>SELECT</mode>
            <json_contenu>{ "code":"400"}</json_contenu>
            <table_name></table_name>
            <id_name></id_name>
            <condition>aucune</condition>
        </requete>`;
}