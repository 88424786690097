import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Settings, FileText, Send, Trash2 } from 'lucide-react';
import '../styles/CollapsibleActions.css';

const CollapsibleActions = ({ 
  user,
  selectedFactures,
  setIsArticleManagerOpen,
  setIsPrepareFactureOpen,
  setIsEnvoiSMSOpen,
  handleDeleteFactures 
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
 // const [isLoaded, setIsLoaded] = useState(false);


 return (
    <div className="collapsible-actions">
      <button 
        className="actions-toggle"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        Actions ({selectedFactures.size} factures sélectionnées)
        {isExpanded ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
      </button>
  
      <div className={`actions-content ${isExpanded ? 'expanded' : ''}`}>
        <div className="actions-buttons">
          <button 
            className="action-buttonca manage"
            hidden={user?.profil === 'SCOLAIRE'}
            onClick={() => setIsArticleManagerOpen(true)}
          >
            <span className="icon-wrapper">
              <Settings size={18} />
            </span>
            <span className="button-text">Gérer</span>
          </button>
          
          <button 
            className="action-buttonca prepare"
            disabled={selectedFactures.size === 0}
            onClick={() => setIsPrepareFactureOpen(true)}
          >
            <span className="icon-wrapper">
              <FileText size={18} />
            </span>
            <span className="button-text">Préparer ({selectedFactures.size})</span>
          </button>
          
          <button 
            className="action-buttonca send"
            onClick={() => setIsEnvoiSMSOpen(true)}
          >
            <span className="icon-wrapper">
              <Send size={18} />
            </span>
            <span className="button-text">Envoyer</span>
          </button>
          
          <button 
            className="action-buttonca delete"
            disabled={selectedFactures.size === 0}
            onClick={handleDeleteFactures}
          >
            <span className="icon-wrapper">
              <Trash2 size={18} />
            </span>
            <span className="button-text">Supprimer ({selectedFactures.size})</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default CollapsibleActions;