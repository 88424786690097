import React from 'react';
import '../styles/FloatingButton.css'; // Assurez-vous d'avoir ce fichier CSS

const FloatingButton = () => {
    const phoneNumber = '221781043505'; // Votre numéro avec l'indicatif international pour le Sénégal
    const message = encodeURIComponent("Bonjour, j'aimerais discuter de votre service."); // Votre message personnalisé

    return (
        <a href={`https://wa.me/${phoneNumber}?text=${message}`} className="floating-button">
            <img src="./images/whatsapp.png" alt="WhatsApp"   /> {/* Assurez-vous d'avoir une icône WhatsApp appropriée */}
        </a>
    );
};

export default FloatingButton;
