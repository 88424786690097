import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const CollapsibleFilters = ({ children, filters, handleFilterChange, user, nomClasses, getUniquePeriodes, factures }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="filters-container">
      <button 
        className="filters-toggle"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="filters-toggle-content">
          <span>Filtres</span>
          {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </div>
      </button>

      <div className={`filters-content ${isExpanded ? 'expanded' : ''}`}>
        <div className="filters">
          <select name="nom_structure" onChange={handleFilterChange}>
            <option value="">Toutes les structures</option>
          </select>
          
          <select 
            name="periode" 
            onChange={handleFilterChange}
            value={filters.periode}
            className="periode-select"
          >
            <option value="">Toutes les périodes</option>
            {getUniquePeriodes(factures).map(periode => (
              <option key={periode} value={periode}>
                {periode}
              </option>
            ))}
          </select>
          
          <select name="libelle_etat" onChange={handleFilterChange}>
            <option value="">Tous les états</option>
            <option value="PAYEE">Payée</option>
            <option value="IMPAYEE">Impayée</option>
          </select>
          
          <input
            type="text"
            name="tel_client"
            placeholder="Téléphone client"
            onChange={handleFilterChange}
          />
          
          <input
            type="text"
            name="nom_client"
            placeholder="Nom du client"
            onChange={handleFilterChange}
          />
          
          <select name="nom_classe" onChange={handleFilterChange}>
            <option value="">{user?.profil === 'SCOLAIRE' ? 'Toutes les classes' : 'Tous les articles'}</option>
            {nomClasses.map((classe, index) => (
              <option key={index} value={classe}>{classe}</option>
            ))}
          </select>
          
          <input
            type="text"
            name="nom_classe_saisi"
            placeholder={user?.profil === 'SCOLAIRE' ? 'Nom de la classe' : 'Nom du produit/service'}
            onChange={handleFilterChange}
          />
        </div>
      </div>
    </div>
  );
};

export default CollapsibleFilters;