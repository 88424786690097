import React from 'react';
import { Page, Text, View, Document, Image, StyleSheet } from '@react-pdf/renderer';
import Barcode from 'react-barcode';

// Styles pour le PDF
const styles = StyleSheet.create({
  page: {
    padding: 30,
    backgroundColor: '#FFFFFF'
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    borderBottom: '2px solid #3498DB',
    paddingBottom: 10
  },
  logoContainer: {
    width: 120,
    marginRight: 20
  },
  logo: {
    width: '100%',
    height: 'auto'
  },
  headerContent: {
    flex: 1,
    textAlign: 'center'
  },
  headerTitle: {
    fontSize: 24,
    color: '#3498DB',
    marginBottom: 5
  },
  headerSubtitle: {
    fontSize: 12,
    color: '#7F8C8D'
  },
  section: {
    margin: 5,
    padding: 10
  },
  sectionTitle: {
    fontSize: 14,
    color: '#2C3E50',
    marginBottom: 5,
    backgroundColor: '#ECF0F1',
    padding: 5
  },
  row: {
    flexDirection: 'row',
    marginBottom: 1
  },
  label: {
    width: '30%',
    fontSize: 10,
    color: '#34495E'
  },
  value: {
    width: '70%',
    fontSize: 10,
    color: '#2C3E50'
  },
  serviceItem: {
    flexDirection: 'row',
    marginBottom: 5,
    fontSize: 10,
    padding: 5
  },
  checkbox: {
    width: 15,
    marginRight: 5
  },
  footer: {
    position: 'absolute',
    bottom: 10,
    left: 10,
    right: 30,
    textAlign: 'center',
    fontSize: 8,
    color: '#95A5A6',
    borderTop: '1px solid #ECF0F1',
    paddingTop: 10
  }
,
signatureSection: {
  marginTop: 10,
  borderTop: '1px solid #BDC3C7',
  paddingTop: 5,
  position: 'relative'
},
signatureContainer0: {
  position: 'relative',
  height: 200,
  width: '100%',
  marginTop: 10,
  marginLeft: 20,     // Ajout d'une marge à gauche
},
signatureContainer: {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: 200,
  width: '100%',
  marginTop: 10
},
leftSignature: {
  position: 'relative',
  width: '40%',
  height: 150
},
rightSection: {
  width: '40%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
},
barcodeContainer: {
  marginBottom: 10
},
directorSignature: {
  width: 150,
  height: 80,
  marginTop: 10
},
directorLabel: {
  fontSize: 8,
  color: '#2C3E50',
  textAlign: 'center',
  marginTop: 5
},
cachetImage: {
  position: 'absolute',
  width: 100,          // Taille du cachet augmentée
  height: 100,
  opacity: 0.4,        // Opacité légèrement augmentée
  top: '50%',
  left:0,
  transform: 'translateY(-50%)', 
  zIndex: 1            // Cachet en dessous
},
signatureImage: {
  position: 'absolute',
  width: 200,          // Taille de la signature ajustée
  height: 100,
  top: '50%',
  transform: 'translateY(-50%)', 
  left:0,
  zIndex: 2            // Signature au-dessus
},
signatureLabel: {      // Nouveau style pour le texte "Signé par"
  position: 'absolute',
  top: 10,
  left: 10,
  fontSize: 10,
  color: '#2C3E50',
  zIndex: 3
}
});

const ConventionPDF = ({ convention, signatureData, cachetData ,structure}) => {
  
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('fr-FR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const getReversementLabel = (type) => {
    const types = {
      'R1': 'Reversement automatique',
      'R2': 'Reversement toutes les 24 heures',
      'R3': 'Reversement hebdomadaire',
      'R4': 'Reversement mensuel'
    };
    return types[type] || type;
  };

  const logoUrl = `${process.env.PUBLIC_URL}/images/logos/logo_ICELABSOFT.png`;
  const directorSignatureUrl = `${process.env.PUBLIC_URL}/images/signatures/directeur_signature.png`;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.logoContainer}>
            <Image src={logoUrl} style={styles.logo} />
          </View>
          <View style={styles.headerContent}>
            <Text style={styles.headerTitle}>Convention de Services</Text>
            <Text style={styles.headerSubtitle}>
              Document généré le {formatDate(new Date())}
            </Text>
            <Text style={styles.headerSubtitle}>
              N° Convention: {convention?.numero_convention}
            </Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Informations de la Structure</Text>
          <View style={styles.row}>
            <Text style={styles.label}>Nom:</Text>
            <Text style={styles.value}>{structure?.nom_structure}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Adresse:</Text>
            <Text style={styles.value}>{structure?.adresse}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Email:</Text>
            <Text style={styles.value}>{structure?.email}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Téléphone:</Text>
            <Text style={styles.value}>{structure?.mobile_om}</Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Détails de la Convention</Text>
          <View style={styles.row}>
            <Text style={styles.label}>Représentant:</Text>
            <Text style={styles.value}>{convention?.representant}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Fonction:</Text>
            <Text style={styles.value}>{convention?.fonction_representant}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>Type de reversement:</Text>
            <Text style={styles.value}>
              {getReversementLabel(convention?.type_reversement)}
            </Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Services Inclus</Text>
          <View style={styles.serviceItem}>
            <Text style={styles.checkbox}>✓</Text>
            <Text style={styles.value}>Dématérialisation de la facturation</Text>
          </View>
          <View style={styles.serviceItem}>
            <Text style={styles.checkbox}>✓</Text>
            <Text style={styles.value}>Journalisation complète des transactions</Text>
          </View>
          <View style={styles.serviceItem}>
            <Text style={styles.checkbox}>✓</Text>
            <Text style={styles.value}>Options de reversement</Text>
          </View>
        </View>

        {(signatureData || cachetData) && (
          <View style={styles.signatureSection}>
            <Text style={styles.sectionTitle}>Signature Électronique</Text>
            <Text style={styles.signatureLabel}>Signé par: {convention?.representant}</Text>
            <View style={styles.signatureContainer}>
              {cachetData && (
                <Image
                  src={cachetData}
                  style={styles.cachetImage}
                />
              )}
              {signatureData && (
                <Image
                  src={signatureData}
                  style={styles.signatureImage}
                />
              )}
            </View>
             {/* Zone code-barres et signature directeur */}
            <View style={styles.rightSection}>
              <View style={styles.barcodeContainer}>
                <Barcode 
                  value={convention.numero_convention}
                  width={1}
                  height={40}
                  fontSize={10}
                />
              </View>
              <Image
                src={directorSignatureUrl}
                style={styles.directorSignature}
              />
              <Text style={styles.directorLabel}>
                Directeur ICELABSOFT
              </Text>
            </View>
          </View>
        
  
  )}
 
        <View style={styles.footer} fixed>
          <Text>Document officiel généré par ICELABSOFT</Text>
          <Text>Email: support@icelabsoft.com | Tél: +XXX XXX XXX</Text>
          <Text>© {new Date().getFullYear()} ICELABSOFT - Tous droits réservés</Text>
        </View>
      </Page>
    </Document>
  );
};

ConventionPDF.defaultProps = {
  convention: {},
  signatureData: null,
  cachetData: null
};

export default ConventionPDF;