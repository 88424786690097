import React, { useState, useEffect } from 'react';
import { getReponseApi } from '../apis/api_backend';
import { uploadFile } from '../apis/api_upload';
import { 
  Building, 
  Upload, 
  Phone, 
  Mail, 
  MapPin, 
  Check,
  AlertCircle,
  Sparkles,
  ArrowRight
} from 'lucide-react';
import '../styles/NewStructure.css';

const DEFAULT_LOGO = `${process.env.PUBLIC_URL}/images/default-logo.png`;
// État initial du formulaire
const initialFormState = {
  id_type: '',
  nom_structure: '',
  adresse: '',
  mobile_om: '',
  email: '',
  logo: DEFAULT_LOGO
};

const NewStructure = () => {  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
//  const [successMessage, setSuccessMessage] = useState('');
  const [typeOptions, setTypeOptions] = useState([]);
  const [logoFile, setLogoFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(DEFAULT_LOGO);
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState({});
  const [fieldStatus, setFieldStatus] = useState({});
  const [credentials, setCredentials] = useState({ login: '', password: '0000',nom_structure: '' });
  // Ajouter après les autres states
  const [showTerms, setShowTerms] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

   // Réinitialiser le formulaire
   const resetForm = () => {
    setFormData(initialFormState);
    setLogoFile(null);
    setPreviewUrl(DEFAULT_LOGO);
    setErrors({});
    setFieldStatus({});
    setCurrentStep(1);
  };

  useEffect(() => {
    fetchTypes();
    // Assurez-vous que l'image par défaut est chargée
    setPreviewUrl(DEFAULT_LOGO);
    document.body.classList.add('new-structure-page');
    return () => document.body.classList.remove('new-structure-page');
  }, []);


  useEffect(() => {
    fetchTypes();
    // Animation d'entrée
    document.body.classList.add('new-structure-page');
    return () => document.body.classList.remove('new-structure-page');
  }, []);

  const fetchTypes = async () => {
    try {
      const response = await getReponseApi('/typestructures', 'GET');
      if (response) setTypeOptions(response);
    } catch (error) {
      console.error('Erreur:', error);
    }
  };

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLogoFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
        setFormData(prev => ({ ...prev, logo: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const validateField = (name, value) => {
    let error = '';
    switch (name) {
      case 'nom_structure':
        if (!value) error = 'Le nom est requis';
        break;
      case 'mobile_om':
        if (!value) error = 'Le numéro est requis';
        else if (!/^\d{9}$/.test(value)) error = 'Format invalide (9 chiffres)';
        break;
      case 'email':
        if (value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value))
          error = 'Email invalide';
        break;
      case 'id_type':
        if (!value) error = 'Sélectionnez un type';
        break;
      case 'adresse':
        if (!value) error = 'L\'adresse est requise';
        break;
      default:
        break;
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    
    // Validation interactive
    const error = validateField(name, value);
    setErrors(prev => ({ ...prev, [name]: error }));
    setFieldStatus(prev => ({ 
      ...prev, 
      [name]: error ? 'error' : value ? 'success' : ''
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
        // Création du FormData avec toutes les données nécessaires
        const submitFormData = new FormData();
        const name = formData.nom_structure;
        // Ajout des champs obligatoires
        submitFormData.append('id_type', formData.id_type);
        submitFormData.append('nom_structure', formData.nom_structure);
        submitFormData.append('adresse', formData.adresse || 'SENEGAL');
        submitFormData.append('mobile_om', formData.mobile_om);
        submitFormData.append('email', formData.email || '');
        
        // Ajout des champs avec valeurs par défaut
        submitFormData.append('mobile_wave', '');
        submitFormData.append('numautorisatioon', 'sen_auto2024');
        submitFormData.append('nummarchand', '');
        submitFormData.append('id_localite', '1');
        submitFormData.append('actif', 'true');

        // Gestion du logo
        if (logoFile) {
            submitFormData.append('logo', logoFile);
          
        } else {
            // Si pas de logo, on laisse le backend utiliser le logo par défaut
            console.log('Utilisation du logo par défaut');
        }

        // Validation des champs requis avant envoi
        const requiredFields = ['id_type', 'nom_structure', 'mobile_om'];
        const missingFields = requiredFields.filter(field => !formData[field]);
        
        if (missingFields.length > 0) {
            throw new Error(`Veuillez remplir les champs suivants : ${missingFields.join(', ')}`);
        }

   

        // Envoi des données
        const response = await uploadFile('/structures', 'POST', submitFormData);

        if (response && response.success !== false) {
            
        
            const message = response.message;
           
            const loginMatch = message.match(/login: (.*?)@fayclick\.net/);
            const login = loginMatch ? `${loginMatch[1]}@fayclick.net` : '';
            
            setCredentials({ login, password: '0000' ,name});
            setShowSuccess(true);
            resetForm(); // Réinitialiser le formulaire immédiatement
      } else {
          throw new Error(response?.error || 'Erreur lors de la création de la structure');
      }
        
    } catch (error) {
        console.error('Erreur lors de la création:', error);
        // Afficher l'erreur dans l'UI (vous devrez ajouter un state pour les erreurs)
        setErrors(prev => ({
            ...prev,
            submit: error.message || 'Erreur lors de la création de la structure'
        }));
    } finally {
        setIsLoading(false);
    }
};



const renderStepIndicator = () => (
  <div className="step-indicator">
    {[1, 2, 3, 4].map(step => ( // Modifié de 3 à 4 étapes
      <div 
        key={step} 
        className={`step ${currentStep === step ? 'active' : ''} 
                   ${currentStep > step ? 'completed' : ''}`}
      >
        {currentStep > step ? <Check size={20} /> : step}
        <span className="step-label">
          {step === 1 ? 'Informations' 
           : step === 2 ? 'Contact' 
           : step === 3 ? 'Conditions' // Nouvelle étape
           : 'Finalisation'}
        </span>
      </div>
    ))}
  </div>
);
// Ajouter avant le SuccessModal
// Composant TermsModal à ajouter avant le SuccessModal
const TermsModal = () => (
  <div className="terms-overlay">
    <div className="terms-modal">
      <h2>Conditions Générales d'Utilisation de FayClick</h2>
      
      <div className="terms-content">
        <div className="terms-section">
          <h3>1. Objet</h3>
          <p>Les présentes Conditions Générales d'Utilisation (« CGU ») ont pour objectif de définir les règles d'utilisation de la plateforme FayClick (« le Service »), fournissant aux structures scolaires la possibilité de recevoir des paiements à distance via Orange Money.</p>
        </div>

        <div className="terms-section">
          <h3>2. Acceptation des CGU</h3>
          <p>Toute utilisation du Service implique l'acceptation sans réserve des présentes CGU. En accédant à FayClick, l'utilisateur (« l'Établissement ») reconnaît avoir pris connaissance des CGU et s'engage à les respecter.</p>
        </div>

        <div className="terms-section">
          <h3>3. Description du Service</h3>
          <p>FayClick permet aux structures marchandes de gérer et de simplifier les paiements à distance de biens et services via Orange Money. Le Service est disponible 24h/24 et 7j/7, sauf interruption pour maintenance.</p>
        </div>

        <div className="terms-section">
          <h3>4. Obligations de la Structure</h3>
          <p>L'La structure s'engage à fournir des informations exactes et complètes lors de l'inscription. Il est responsable de la protection de ses identifiants de connexion et de toute activité effectuée via son compte.</p>
        </div>

        <div className="terms-section">
          <h3>5. Responsabilité</h3>
          <p>FayClick ne saurait être tenu responsable de toute interruption, défaillance technique, ou des conséquences qui pourraient en découler. Les transactions étant effectuées via Orange Money, la structure accepte les conditions d'utilisation de ce service.</p>
          <p>Toute détérioration des services ou utilisation frauduleuse de la plateforme sera sanctionnée, pouvant aller jusqu'à la suspension de l'accès au service.</p>
        </div>

        <div className="terms-section">
          <h3>6. Tarification</h3>
          <p>L'utilisation de FayClick est soumise à des frais de service prélevés pour chaque transaction effectuée. Les montants prélevés sont clairement indiqués lors du processus de paiement.</p>
        </div>

        <div className="terms-section">
          <h3>7. Protection des données</h3>
          <p>Les données collectées sont traitées conformément à la réglementation en vigueur sur la protection des données personnelles. Les informations fournies par l'Établissement ne sont utilisées qu'à des fins de gestion des paiements.</p>
        </div>

        <div className="terms-section">
          <h3>8. Modification des CGU</h3>
          <p>FayClick se réserve le droit de modifier les présentes CGU à tout moment. Les modifications seront publiées sur le site et entrent en vigueur dès leur mise en ligne.</p>
        
        </div>

        <div className="terms-section">
          <h3>9. Contact</h3>
          <p>Pour toute question relative aux CGU, veuillez contacter FayClick via le site officiel : <a href="https://fayclick.net" target="_blank" rel="noopener noreferrer">https://fayclick.net</a>.</p>
          <p className="address">Adresse : Sacré-Coeur 3, villa 128 4ème Phase, Rue 41 B.P. 22290 DAKAR SENEGAL</p>
        </div>
      </div>

      <div className="terms-actions">
        <label className="terms-checkbox">
          <input 
            type="checkbox" 
            checked={termsAccepted}
            onChange={(e) => setTermsAccepted(e.target.checked)}
          />
          <span>J'accepte les conditions générales d'utilisation</span>
        </label>
        
        <div className="terms-buttons">
          <button 
            onClick={() => setShowTerms(false)}
            className="terms-button cancel"
          >
            Fermer
          </button>
          <button 
            onClick={() => {
              if (termsAccepted) {
                setShowTerms(false);
                setCurrentStep(4);
              }
            }}
            className={`terms-button confirm ${!termsAccepted ? 'disabled' : ''}`}
            disabled={!termsAccepted}
          >
            Confirmer
          </button>
        </div>
      </div>
    </div>
  </div>
);
  const SuccessModal = () => (
    <div className="success-overlay">
      <div className="success-modal">
        <div className="check-icon-wrapper">
          <Check size={48} className="success-icon" />
        </div>
        <h2>{credentials.name} créée avec succès!</h2>
        
        <div className="credentials-container">
          <div className="credential-item">
            <span className="credential-label">Login</span>
            <span className="credential-value">{credentials.login}</span>
          </div>
          <div className="credential-item">
            <span className="credential-label">Mot de passe</span>
            <span className="credential-value">{credentials.password}</span>
          </div>
        </div>

        <div className="security-notice">
          <AlertCircle size={16} />
          <p>Pour votre sécurité, changez votre mot de passe dès votre première connexion dans le menu "Mon Compte".</p>
        </div>

        <button 
          onClick={() => window.location.href = '/login'} 
          className="login-button"
        >
          Je me connecte
        </button>
      </div>
    </div>
  );
  return (
    <div className="newstructurepage">
    <div className="new-structure-container">
      <div className="form-card">
        <div className="card-header">
          <h1>
            <Building className="header-icon" />
            Créer votre Structure
            <Sparkles className="sparkle-icon" />
          </h1>
          {renderStepIndicator()}
        </div>

        <form onSubmit={handleSubmit} className="structure-form">
          <div className={`step-content ${currentStep === 1 ? 'active' : ''}`}>
            <div className="logo-upload-section">
              <div 
                className="logo-preview" 
                style={{ backgroundImage: `url(${previewUrl})` }}
              >
                <input
                  type="file"
                  onChange={handleLogoChange}
                  accept="image/*"
                  id="logo-input"
                  className="hidden-input"
                />
                <label htmlFor="logo-input" className="upload-overlay">
                  <Upload size={24} />
                  <span>Changer le logo</span>
                </label>
              </div>
            </div>

            <div className="form-group">
            
              <select
                name="id_type"
                value={formData.id_type}
                onChange={handleChange}
                className={fieldStatus.id_type}
              >
                <option value="">Sélectionnez un type</option>
                {typeOptions.map(type => (
                  <option key={type.id_type} value={type.id_type}>
                    {type.nom_type}
                  </option>
                ))}
              </select>
              {errors.id_type && <span className="error-message">{errors.id_type}</span>}
            </div>

            <div className="form-group">
            
              <div className="input-wrapper">
                <Building className="field-icon" size={18} />
                <input
                  type="text"
                  name="nom_structure"
                  value={formData.nom_structure}
                  onChange={handleChange}
                  className={fieldStatus.nom_structure}
                  placeholder="Entrez le nom de votre structure"
                />
              </div>
              {errors.nom_structure && <span className="error-message">{errors.nom_structure}</span>}
            </div>
          </div>

          <div className={`step-content ${currentStep === 2 ? 'active' : ''}`}>
            <div className="form-group">
             
              <div className="input-wrapper">
                <MapPin className="field-icon" size={18} />
                <input
                  type="text"
                  name="adresse"
                  value={formData.adresse}
                  onChange={handleChange}
                  className={fieldStatus.adresse}
                  placeholder="Adresse complète"
                />
              </div>
              {errors.adresse && <span className="error-message">{errors.adresse}</span>}
            </div>

            <div className="form-group">
            
              <div className="input-wrapper">
                <Phone className="field-icon" size={18} />
                <input
                  type="text"
                  name="mobile_om"
                  value={formData.mobile_om}
                  onChange={handleChange}
                  className={fieldStatus.mobile_om}
                  placeholder="77XXXXXXX"
                  maxLength="9"
                />
              </div>
              {errors.mobile_om && <span className="error-message">{errors.mobile_om}</span>}
            </div>

            <div className="form-group">
            
              <div className="input-wrapper">
                <Mail className="field-icon" size={18} />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={fieldStatus.email}
                  placeholder="email@domaine.com"
                />
              </div>
              {errors.email && <span className="error-message">{errors.email}</span>}
            </div>
          </div>
                
          <div className={`step-content ${currentStep === 3 ? 'active' : ''}`}>
          <div className="summary-section">
              
                <div className="summary-content">
                    <div className="summary-item">
                    <strong>Type</strong>
                    <span>{typeOptions.find(t => t.id_type === parseInt(formData.id_type))?.nom_type || '-'}</span>
                    </div>
                    <div className="summary-item">
                    <strong>Nom</strong>
                    <span>{formData.nom_structure || '-'}</span>
                    </div>
                    <div className="summary-item">
                    <strong>Adresse</strong>
                    <span>{formData.adresse || '-'}</span>
                    </div>
                    <div className="summary-item">
                    <strong>Contact</strong>
                    <span>{formData.mobile_om || '-'}</span>
                    </div>
                </div>
                </div>
          </div>
          <div className="form-navigation">
              {currentStep > 1 && (
                <button
                  type="button"
                  className="nav-button prev"
                  onClick={() => setCurrentStep(prev => prev - 1)}
                >
                  Précédent
                </button>
              )}
              {currentStep < 3 ? (
                <button
                  type="button"
                  className="nav-button next"
                  onClick={() => setCurrentStep(prev => prev + 1)}
                >
                  Suivant <ArrowRight size={16} />
                </button>
              ) : currentStep === 3 ? (
                <button
                  type="button"
                  className="nav-button next"
                  onClick={() => setShowTerms(true)}
                >
                  Voir les conditions <ArrowRight size={16} />
                </button>
              ) : (
                <button
                  type="submit"
                  className="submit-button"
                  disabled={isLoading || !termsAccepted}
                >
                  {isLoading ? 'Création...' : 'Créer ma structure'}
                  <Sparkles className="button-sparkle" size={16} />
                </button>
              )}
            </div>
        </form>
      </div>
      {showTerms && <TermsModal />}
      {showSuccess && <SuccessModal />}
    </div>
    </div> 
  );
};

export default NewStructure;