import React, { useState, useEffect, useCallback } from 'react';
import {  Phone, ArrowRight, AlertCircle } from 'lucide-react';
import { getReponseApi } from '../apis/api_backend';
import ProcessingStep from './ProcessingStep';
import '../styles/PopupPayement.css';


const PaymentSteps = {
  BILLING: 'BILLING',
  CONFIRMATION: 'CONFIRMATION',
  PROCESSING: 'PROCESSING'
};

// Tableaux des seuils et frais correspondants
const tranches = [
  1000,   // min de la première tranche
  15001,  // début de la deuxième tranche
  40001,  // début de la troisième tranche
  65001,  // etc...
  85001,
  100001,
  125001,
  150001,
  200000  // max de la dernière tranche
];

const frais = [
  540,    // frais pour 1000-15000
  1080,   // frais pour 15001-40000
  1305,   // frais pour 40001-65000
  1602,   // frais pour 65001-85000
  1998,   // frais pour 85001-100000
  2457,   // frais pour 100001-125000
  2997,   // frais pour 125001-150000
  3798    // frais pour 150001-200000
];

// Fonction de calcul des frais mise à jour
const getFraisInterpolated = (factureMontant) => {
  // Si le montant est inférieur au minimum
  if (factureMontant < tranches[0]) {
    return frais[0];
  }
  
  // Si le montant est supérieur au maximum
  if (factureMontant > tranches[tranches.length - 1]) {
    return frais[frais.length - 1];
  }

  // Trouver la tranche correspondante
  for (let i = 0; i < tranches.length - 1; i++) {
    if (factureMontant >= tranches[i] && factureMontant <= tranches[i + 1]) {
      return frais[i];
    }
  }

  // Par sécurité, retourner les frais de la première tranche
  return frais[0];
};

const PopupPayement = ({ factures , onClose, onPaymentComplete }) => {
  
  const [currentStep, setCurrentStep] = useState(PaymentSteps.BILLING);
  const [telephone, setTelephone] = useState(factures[0]?.tel_client || '');
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentId, setPaymentId] = useState(null);
  const [avecSmsRappel, setAvecSmsRappel] = useState(false);

  // Calculer le montant total
  const montant = factures.reduce((sum, f) => sum + parseFloat(f.montant), 0);
  // Calculer les frais une seule fois sur le montant total 
  // ********* A REVOIR POUR LES STRUCTURES OU LES FRAIS SONT ADMIS********
  //const serviceFrais = factures[0]?.avec_frais === false ? 0 : getFraisInterpolated(montant);

  const [serviceFrais, setServiceFrais] = useState(0);
  const netTotal = (montant + serviceFrais).toFixed(2);

// Fonction pour obtenir les frais depuis la grille tarifaire
const getFraisFromGrille = (montant, grilleTarifaire) => {
  if (!grilleTarifaire || !Array.isArray(grilleTarifaire)) {
    return null;
  }

  for (const tarif of grilleTarifaire) {
    const [min, max] = tarif.tranche.split('-').map(num => parseInt(num));
    if (montant >= min && montant <= max) {
      // Si tarif_applique est négatif, on le traite comme un pourcentage
      if (tarif.tarif_applique < 0) {
        // On prend la valeur absolue du tarif et on l'applique comme pourcentage
        return (montant * Math.abs(tarif.tarif_applique)) / 100;
      }
      // Sinon on retourne le tarif fixe
      return tarif.tarif_applique;
    }
  }
  return null;
};

// Modifier la définition de calculerFrais pour utiliser useCallback
const calculerFrais = useCallback(async () => {
  try {
    if (factures[0]?.avec_frais === false) {
      setServiceFrais(0);
      return;
    }

    const response = await getReponseApi(`/structures/grille_tarif/${factures[0].id_structure}`, 'GET');
    console.log('Grille tarifaire:', response);
    if (response && response.length > 0) {
      const fraisFromGrille = getFraisFromGrille(montant, response);
      if (fraisFromGrille !== null) {
        console.log('Frais depuis la grille tarifaire:', fraisFromGrille);
        setServiceFrais(fraisFromGrille);
        return;
      }
    }
    
    const fraisDefault = getFraisInterpolated(montant);
    setServiceFrais(fraisDefault);

  } catch (error) {
    console.error('Erreur lors du chargement de la grille tarifaire:', error);
    const fraisDefault = getFraisInterpolated(montant);
    setServiceFrais(fraisDefault);
  }
}, [montant, factures]); // Ajouter toutes les dépendances

// Modifier useEffect en conséquence
useEffect(() => {
  calculerFrais();
}, [calculerFrais]); // calculerFrais est maintenant une dépendance stable

  if (!Array.isArray(factures) || factures.length === 0) {
    console.error('Factures invalides:', factures);
    return (
      <div className="payment-popup">
        <div className="payment-container">
          <button className="closepop_pay-button" onClick={onClose}>×</button>
          <div className="error-message">
            Erreur : Aucune facture valide à traiter
          </div>
        </div>
      </div>
    );
  }

  const handleTelephoneChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,9}$/.test(value)) {
      setTelephone(value);
    }
  };

  const handleNextStep = () => {
    if (currentStep === PaymentSteps.BILLING) {
      if (!telephone || telephone.length !== 9) {
        alert('Veuillez saisir un numéro de téléphone valide (9 chiffres).');
        return;
      }
      setCurrentStep(PaymentSteps.CONFIRMATION);
    }
  };

  const handleConfirmPayment = async () => {
    setIsProcessing(true);
    try {
      const body = {
        nom_app: 'ICELABOSOFT',
        nom_structure: factures[0].nom_structure,
        numfacture: factures[0].num_facture, // Utiliser le numéro de facture au lieu de factures.map(f => f.num_facture]}f.num_facture).join(','), // Concaténer les numéros de facture
        numtelclient: telephone,
        montant: (netTotal),
        frais: serviceFrais,
        avec_infosms: avecSmsRappel,
        others_num_facture: factures.length > 1 ? factures.slice(1).map(f => f.num_facture).join('#') : ''
      };
      
      const response = await getReponseApi('/factures/newpayement', 'POST', body);
      if (response.error) throw new Error(response.error);
      
      setCurrentStep(PaymentSteps.PROCESSING);
    
      setPaymentId(response.new_id_demande);

    } catch (error) {
      console.error('Erreur:', error);
      alert('Une erreur est survenue lors de la création de la demande de paiement.');
      setIsProcessing(false);
    }
  };

  const formatMontant = (montant) => {
    return Math.floor(montant).toLocaleString('fr-FR', {
      useGrouping: true,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }) + ' FCFA';
  };

  const renderBillingStep = () => (
    <div className="step-content billing">
      <div className="billing-header">
        <div className="logo-row">
        <div className="facture-logo">
              <img src={factures[0].logo} alt="Logo" />
            </div>
          <span className="highlight">{factures[0].nom_structure}</span>
          </div>
          
      </div>
      <div className="invoice-entete">
          <h3>{factures.length > 1 ? 'Détails des Factures' : 'Référence de la Facture' }</h3>
          <div className="invoice-numbers">
            {factures.length > 1 ? factures.map(f => f.num_facture).join(' - ') : factures[0].num_facture}
          </div>  
        </div>
      <div className="billing-details">
    
        <div className="detail-row">
          <span>Descriptions</span>
          <div className="descriptions-list">
            {factures.map((f, index) => (
              <div key={f.num_facture} className="description-item">
                {`${f.nom_classe} : ${f.nom_client}`}
              </div>
            ))}
          </div>
        </div>
        <div className="detail-row">
          <span>Montant total</span>
          <span className="amount">{formatMontant(montant)}</span>
        </div>
        <div className="detail-row">
          <span>Frais de service</span>
          <span className="amount-secondary">{formatMontant(serviceFrais)}</span>
        </div>
        <div className="detail-row total">
          <span>Total à payer</span>
          <span className="amount-total">{formatMontant(netTotal)}</span>
        </div>
      </div>

      <div className="phone-input-section">
        <label>
          <Phone size={20} />
          <span>Numéro Orange Money</span>
        </label>
        <input
          type="text"
          value={telephone}
          onChange={handleTelephoneChange}
          placeholder="Entrez votre numéro"
          maxLength="9"
        />
        <small>Entrez le numéro qui possède Orange Money</small>
      </div>

      <button 
        className="next-step-button"
        onClick={handleNextStep}
      >
        Continuer vers la confirmation
        <ArrowRight size={20} />
      </button>
    </div>
  );
  const renderStepIndicator = () => (
    <div className="steps-indicator">
 

      <div className={`step ${currentStep === PaymentSteps.BILLING ? 'active' : ''} ${currentStep !== PaymentSteps.BILLING ? 'completed' : ''}`}>
        <div className="step-number">1</div>
        <span className="step-label">Facturation</span>
      </div>
      <div className="step-line" />
      <div className={`step ${currentStep === PaymentSteps.CONFIRMATION ? 'active' : ''} ${currentStep === PaymentSteps.PROCESSING ? 'completed' : ''}`}>
        <div className="step-number">2</div>
        <span className="step-label">Confirmation</span>
      </div>
      <div className="step-line" />
      <div className={`step ${currentStep === PaymentSteps.PROCESSING ? 'active' : ''}`}>
        <div className="step-number">3</div>
        <span className="step-label">Traitement</span>
      </div>
    </div>
  );

  const renderConfirmationStep = () => (
    <div className="step-content confirmation">
      <div className="confirmation-icon">
        <AlertCircle size={48} />
      </div>
      
      <h3>Confirmez votre paiement</h3>
      
      <div className="confirmation-details">
        <p>
          {factures.length > 1 ? (
              <>Vous payez les factures <strong>N°{factures.map(f => f.num_facture).join(', ')}</strong></>
          ) : (
              <>Vous payez la facture <strong>N°{factures[0].num_facture}</strong></>
          )}
      </p>
        <p>Montant total: <strong>{formatMontant(netTotal)}</strong></p>
        <p>Via le numéro: <strong>{telephone}</strong></p>
      </div>
      <div className="sms-reminder-section">
        <label className="checkbox-label">
          <input
            type="checkbox"
            checked={avecSmsRappel}
            onChange={(e) => setAvecSmsRappel(e.target.checked)}
          />
          <span>Recevoir un SMS de rappel</span>
        </label>
        <small>Cochez cette case pour recevoir un SMS de rappel pour le paiement</small>
      </div>
      <div className="confirmation-notice">
       {avecSmsRappel && <p>Un SMS de rappel vous sera envoyé</p>}
        <p>Vous devez avoir l'application Orange Money ou Maxit.</p>
      </div>

      <div className="confirmation-actions">
        <button 
          className="back-button"
          onClick={() => setCurrentStep(PaymentSteps.BILLING)}
          disabled={isProcessing}
        >
          Retour
        </button>
        <button 
          className="confirm-button"
          onClick={handleConfirmPayment}
          disabled={isProcessing}
        >
          {isProcessing ? 'Traitement...' : 'Confirmer le paiement'}
        </button>
      </div>
    </div>
  );

  const renderProcessingStep = () => (
  <ProcessingStep 
   
  factures={factures}
    onClose={onClose}
    paymentId={paymentId}
  />
);

  return (
    <div className="payment-popup">
          
      <div className="payment-container">
        <button className="closepop_pay-button" onClick={onClose}>×</button>
        {renderStepIndicator()}
        
        <div className="step-content-container">
          {currentStep === PaymentSteps.BILLING && renderBillingStep()}
          {currentStep === PaymentSteps.CONFIRMATION && renderConfirmationStep()}
          {currentStep === PaymentSteps.PROCESSING && renderProcessingStep()}
        </div>
      </div>
    </div>
  );
};

export default PopupPayement;