import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { DollarSign, ChevronRight } from 'lucide-react';
import './styles/PopupFraisApplique.css';

const PopupFraisApplique = ({ isOpen, setIsOpen }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  
  const fraisData = [
    { range: "1 000 - 20 000", montant: "540" },
    { range: "15 001 - 40 000", montant: "1 080" },
    { range: "40 001 - 65 000", montant: "1 305" },
    { range: "65 001 - 85 000", montant: "1 602" },
    { range: "85 001 - 100 000", montant: "1 998" },
    { range: "100 001 - 125 000", montant: "2 457" },
    { range: "125 001 - 150 000", montant: "2 997" },
    { range: "150 001 - 200 000", montant: "3 798" }
  ];

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  return (
    <Modal 
      show={isOpen} 
      onHide={() => setIsOpen(false)}
      centered
      className="fees-modal"
      size="lg"
    >
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="w-100 text-center position-relative">
          <div className="modal-title-content">
            <DollarSign className="title-icon" size={24} />
            <span className="ms-2">Grille des Frais Appliqués</span>
          </div>
          <div className="title-underline"></div>
        </Modal.Title>
      </Modal.Header>
      
      <Modal.Body className="p-4">
        <div className="fees-container">
          {fraisData.map((item, index) => (
            <div
              key={index}
              className="fee-item"
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              style={{
                animationDelay: `${index * 0.1}s`
              }}
            >
              <div className="fee-content">
                <div className="fee-range">
                  <div className="pulse-dot"></div>
                  <span className="range-text">
                    {formatNumber(item.range.split(" - ")[0])} - {formatNumber(item.range.split(" - ")[1])}
                  </span>
                </div>
                
                <div className="fee-amount">
                  <span className="amount-text">
                    {formatNumber(item.montant)}
                  </span>
                  <ChevronRight 
                    size={18} 
                    className={`chevron-icon ${hoveredIndex === index ? 'chevron-active' : ''}`}
                  />
                </div>
              </div>
              
              <div className="holographic-effect"></div>
            </div>
          ))}
        </div>

        <div className="disclaimer">
          <div className="disclaimer-icon">ℹ️</div>
          <span>Les montants sont exprimés en Francs CFA</span>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PopupFraisApplique;