import React, { useContext } from 'react';
import { PayEcoleContext } from '../contexts/PayEcoleContext';
import DynamicNavbar from './DynamicNavbar';
import { Link } from 'react-router-dom';
import './styles/Header.css';

const Header = () => {
  const { user } = useContext(PayEcoleContext);

  return (
    <header>
      <div className="header-container">
        <Link to="/" className="logo-container">
          <img 
            src={`${process.env.PUBLIC_URL}/images/logos/sycad_logo.png`}
            alt="F@yClic Logo" 
            loading="eager"
          />
        </Link>
        <div className="header-text">
          <h1>PAIEMENTS SÉCURISÉS ET FIABLES</h1>
          <h2>ÉVITER LES TRACASSERIES ET EMBOUTEILLAGES</h2>
        </div>
      </div>
      {user && <DynamicNavbar />}
    </header>
  );
};

export default Header;