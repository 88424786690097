import React, { useState } from 'react';
import {  Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Info, Heart, FileText, DollarSign } from 'lucide-react';
import PopupFraisApplique from './PopupFraisApplique';
import './styles/Footer.css';

const Footer = () => {
  const navigate = useNavigate();
  const [isFeesModalOpen, setIsFeesModalOpen] = useState(false);
  const [hoveredIcon, setHoveredIcon] = useState(null);

  const goToAPropos = () => {
    navigate('/apropos');
  };

  const goToTerms = () => {
    navigate('/terms');
  };

  const openFeesModal = () => {
    setIsFeesModalOpen(true);
  };

  const FooterLink = ({ icon: Icon, text, onClick, id }) => (
    <div 
      className="footer-link-container d-flex align-items-center gap-2"
      onClick={onClick}
      onMouseEnter={() => setHoveredIcon(id)}
      onMouseLeave={() => setHoveredIcon(null)}
    >
      <div className={`icon-wrapper ${hoveredIcon === id ? 'icon-pulse' : ''}`}>
        <Icon 
          size={18} 
          className="footer-icon"
          strokeWidth={2}
        />
      </div>
      <span className="footer-text">{text}</span>
    </div>
  );

  return (
    <>
      <footer className="modern-footer">
        <div className="contener-footer">
          <Row className="align-items-center py-4">
            <Col xs={12} md={4} className="footer-col">
              <div className="d-flex align-items-center justify-content-start gap-4">
                <FooterLink 
                  icon={Info} 
                  text="À propos" 
                  onClick={goToAPropos}
                  id="about"
                />
                <FooterLink 
                  icon={DollarSign} 
                  text="Nos Frais" 
                  onClick={openFeesModal}
                  id="fees"
                />
              </div>
            </Col>
            
            <Col xs={12} md={4} className="footer-col text-center py-3 py-md-0">
              <div className="brand-container">
                <Heart 
                  size={20} 
                  className="brand-icon"
                  fill="#0d6efd"
                  strokeWidth={1.5}
                />
                <span className="ms-2 footer-brand">© 2023 ICELABOSOFT</span>
              </div>
            </Col>
            
            <Col xs={12} md={4} className="footer-col">
              <div className="d-flex align-items-center justify-content-md-end">
                <FooterLink 
                  icon={FileText} 
                  text="Conditions Générales" 
                  onClick={goToTerms}
                  id="terms"
                />
              </div>
            </Col>
          </Row>
        </div>
      </footer>

      <PopupFraisApplique 
        isOpen={isFeesModalOpen} 
        setIsOpen={setIsFeesModalOpen}
      />
    </>
  );
};

export default Footer;