import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import '../styles/ImportCarousel.css';

const ImportCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      image: `${process.env.PUBLIC_URL}/images/model_ecole.png`,
      title: "Format pour les établissements",
      downloadLink: `${process.env.PUBLIC_URL}/documents/model_Ecole.xlsx`
    },
    {
      image: `${process.env.PUBLIC_URL}/images/model_commerce.png`,
      title: "Format pour les commerces",
      downloadLink: `${process.env.PUBLIC_URL}/documents/model_Commerce.xlsx`
    },
    {
      image: `${process.env.PUBLIC_URL}/images/model_Service.png`,
      title: "Format pour les services",
      downloadLink: `${process.env.PUBLIC_URL}/documents/model_service.xlsx`
    }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => 
        prevSlide === slides.length - 1 ? 0 : prevSlide + 1
      );
    }, 5000);

    return () => clearInterval(timer);
  }, [slides.length]);

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  const goToPrevious = () => {
    setCurrentSlide((prevSlide) => 
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  const goToNext = () => {
    setCurrentSlide((prevSlide) => 
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  const getTransformValue = () => {
    return `translateX(-${currentSlide * 100}%)`;
  };

  return (
    <div className="carousel-container">
      <div className="carousel-wrapper" style={{ transform: getTransformValue() }}>
        {slides.map((slide, index) => (
          <div key={index} className="carousel-slide">
            <a href={slide.downloadLink} className="download-link" download>
              <img
                src={slide.image}
                alt={slide.title}
                className="carousel-image"
              />
              <div className="carousel-content">
                <h3 className="carousel-title">{slide.title}</h3>
                <p className="carousel-text">Cliquez pour télécharger le format</p>
              </div>
            </a>
          </div>
        ))}
      </div>

      {/* Boutons précédent/suivant */}
      <button 
        className="carousel-button prev"
        onClick={goToPrevious}
      >
        <ChevronLeft />
      </button>
      <button 
        className="carousel-button next"
        onClick={goToNext}
      >
        <ChevronRight />
      </button>

      {/* Navigation par points */}
      <div className="carousel-nav">
        {slides.map((_, index) => (
          <button
            key={index}
            className={`carousel-dot ${currentSlide === index ? 'active' : ''}`}
            onClick={() => goToSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default ImportCarousel;
