// src/App.js
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import SessionTimeout from './contexts/SessionTimeout';
import { PayEcoleProvider } from './contexts/PayEcoleContext';
import { AuthProvider } from './contexts/AuthContext';
import AppContent from './AppContent';
import './App.css';

const App = () => {
  return (
    <AuthProvider>
      <PayEcoleProvider>
        <Router>
          <SessionTimeout timeout={180000} />
          <AppContent />
        </Router>
      </PayEcoleProvider>
    </AuthProvider>
  );
};

export default App;