import React from 'react';
import { createRoot } from 'react-dom/client';
import { Helmet } from 'react-helmet';
import './index.css';
import App from './Apps';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Helmet>
      <title>FayClick - Solution de Facturation et de Paiement à distance</title>
      <meta name="description" content="FayClick est une solution innovante de gestion des paiements de factures permettant aux structures de gérer efficacement leurs factures  et aux clients de payer en toute sécurité." />
      <meta name="keywords" content="paiement mobile, facturation à distance, paiement en ligne, école, éducation" />
      <meta name="author" content="IceLaboSoft" />
      
      {/* Autres métadonnées importantes */}
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      <meta name="theme-color" content="#000000" />
      <link rel="canonical" href="https://icelabsoft.com/" />
    </Helmet>
    <App />
  </React.StrictMode>
);

reportWebVitals();