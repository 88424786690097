import React, { useContext, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { PayEcoleContext } from '../contexts/PayEcoleContext';
import { 
  Menu, 
  X, 
  Home,
  Building2,
  Receipt,
  UserCircle,
  LogOut
} from 'lucide-react';
import './styles/Navbar.css';

const DynamicNavbar = () => {
  const { user, logout } = useContext(PayEcoleContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  // Fonction de déconnexion sécurisée
  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  // Nouvelle fonction pour gérer le clic sur le home-link
  const handleHomeClick = (e) => {
    //e.preventDefault(); // Empêche la navigation par défaut
    //handleLogout(); // Déconnexion avant redirection
    if (user.code_groupe === 0) {
      navigate('/homeclient');
    }
    else 
     {
      navigate('/mastructure');
    };
  };
  const handleProfileClick = () => {
    navigate('/compte');
  };

  // Si pas d'utilisateur, ne pas afficher la navbar
  if (!user) return null;

  const renderNavLinks = () => {
    const links = [
      { to: "/mastructure", label: "Ma structure", icon: <Building2 size={20} /> },
      { to: "/paiements", label: "Mes Factures", icon: <Receipt size={20} /> },
      { to: "/compte", label: "Mon compte", icon: <UserCircle size={20} /> }
    ];

    return links.map((link) => (
      <Link
        key={link.to}
        to={link.to}
        className={`nav-link ${location.pathname === link.to ? 'active' : ''}`}
      >
        <span className="nav-icon">{link.icon}</span>
        <span className="nav-text">{link.label}</span>
      </Link>
    ));
  };

  return (
    <nav className="navbar-main">
      <div className="navbar-container">
        <button onClick={handleHomeClick} className="home-link">
          <Home size={24} />
        </button>

        <div className="nav-links-desktop">
          {renderNavLinks()}
        </div>

        <button
          className="hamburger-btn"
          onClick={() => setIsOpen(!isOpen)}
          aria-label="Menu"
        >
          {isOpen ? <X size={24} /> : <Menu size={24} />}
        </button>

        <div className={`mobile-menu ${isOpen ? 'show' : ''}`}>
          {renderNavLinks()}
          <button onClick={handleLogout} className="nav-link logout-btn">
            <LogOut size={20} />
            <span>Déconnexion</span>
          </button>
        </div>

        <div className="nav-actions">
          <button onClick={handleLogout} className="nav-link logout-btn">
            <span className="nav-text">Déconnexion</span>
          </button>
          <button onClick={handleProfileClick} className="profile-btn">
            <UserCircle size={24} />
          </button>
        </div>
      </div>
    </nav>
  );
};

export default DynamicNavbar;