import React from 'react';

const FilterSelects = ({ 
  selectedStructure, 
  selectedClasse, 
  structures, 
  classes, 
  setSelectedStructure, 
  setSelectedClasse 
}) => (
  <div className="filter-selects-container">
    <div className="filter-select-wrapper">
      <select
        value={selectedStructure}
        onChange={(e) => setSelectedStructure(e.target.value)}
        className="filter-select"
      >
        <option value="">Toutes les structures</option>
        {structures.map((structure) => (
          <option key={structure} value={structure}>
            {structure}
          </option>
        ))}
      </select>
    </div>
    
    <div className="filter-select-wrapper">
      <select
        value={selectedClasse}
        onChange={(e) => setSelectedClasse(e.target.value)}
        className="filter-select"
      >
        <option value="">Toutes les rubriques</option>
        {classes.map((classe) => (
          <option key={classe} value={classe}>
            {classe}
          </option>
        ))}
      </select>
    </div>
  </div>
);

export default FilterSelects;