import React from 'react';
import { motion } from 'framer-motion';
import { 
  Building2, Users, ShoppingBag, Gift, 
  UserPlus, Upload, Send, Search, 
  LayoutGrid, Receipt, ScrollText,
  Shield, Clock, Wallet,
  MonitorSmartphone, ShieldCheck, Timer
} from 'lucide-react';

// Système de thèmes de couleurs enrichi
const colorThemes = {
  main: {
    primary: 'from-indigo-600 to-violet-600',
    secondary: 'from-indigo-500/10 to-violet-500/10'
  },
  presentation: {
    primary: 'from-blue-600 to-blue-700',
    secondary: 'from-blue-500/10 to-blue-600/10',
    text: 'text-blue-600',
    hover: 'hover:text-blue-700',
    background: 'bg-blue-50',
    border: 'border-blue-200',
    icon: 'text-blue-600',
    gradient: 'from-blue-600/20 to-blue-700/20'
  },
  howItWorks: {
    primary: 'from-indigo-600 to-indigo-700',
    secondary: 'from-indigo-500/10 to-indigo-600/10',
    text: 'text-indigo-600',
    hover: 'hover:text-indigo-700',
    background: 'bg-indigo-50',
    border: 'border-indigo-200',
    icon: 'text-indigo-600',
    gradient: 'from-indigo-600/20 to-indigo-700/20'
  },
  advantages: {
    primary: 'from-violet-600 to-violet-700',
    secondary: 'from-violet-500/10 to-violet-600/10',
    text: 'text-violet-600',
    hover: 'hover:text-violet-700',
    background: 'bg-violet-50',
    border: 'border-violet-200',
    icon: 'text-violet-600',
    gradient: 'from-violet-600/20 to-violet-700/20'
  }
};

// Animation variants
const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { duration: 0.6, ease: "easeOut" }
  }
};

const PremiumCard = ({ children, className = '', delay = 0, theme }) => (
  <motion.div 
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true }}
    variants={{
      hidden: { opacity: 0, y: 50 },
      visible: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.7,
          delay,
          ease: "easeOut"
        }
      }
    }}
    className={`
      relative overflow-hidden
      bg-white/90 backdrop-blur-xl p-8 rounded-xl
      before:absolute before:inset-0 
      before:bg-gradient-to-r before:${theme?.gradient || colorThemes.main.secondary}
      before:opacity-0 before:hover:opacity-100 
      before:transition-all before:duration-1000
      shadow-[0_8px_30px_rgb(0,0,0,0.12)] 
      hover:shadow-[0_8px_30px_rgb(0,0,0,0.16)]
      border border-white/30
      hover:border-${theme?.border || 'indigo-200'}
      transition-all duration-500 ease-out
      ${className}
    `}
    whileHover={{ 
      scale: 1.01,
      transition: { duration: 0.3 }
    }}
  >
    <div className="relative z-10">{children}</div>
    <div className={`
      absolute inset-0 bg-gradient-to-br 
      ${theme?.secondary || colorThemes.main.secondary}
      opacity-0 hover:opacity-100 
      transition-opacity duration-700 
      pointer-events-none
    `} />
  </motion.div>
);

const IconWrapper = ({ children, theme }) => (
  <motion.div 
    className="relative"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.98 }}
  >
    <motion.div
      className={`
        absolute inset-0 
        bg-gradient-to-br ${theme?.gradient || colorThemes.main.secondary}
        rounded-full blur-xl
      `}
      animate={{
        scale: [1, 1.2, 1],
        opacity: [0.3, 0.5, 0.3]
      }}
      transition={{
        duration: 3,
        repeat: Infinity,
        ease: "easeInOut"
      }}
    />
    <div className={`relative z-10 ${theme?.text || colorThemes.main.primary}`}>
      {children}
    </div>
  </motion.div>
);

const SectionTitle = ({ icon: Icon, title, theme }) => (
  <div className="flex items-center gap-3 mb-6">
    <IconWrapper theme={theme}>
      <div className={`
        p-2 rounded-xl
        bg-gradient-to-br ${theme?.gradient || colorThemes.main.secondary}
      `}>
        <Icon className={`w-8 h-8 ${theme?.icon || colorThemes.main.primary}`} />
      </div>
    </IconWrapper>
    <h2 className={`
      text-2xl font-bold
      bg-gradient-to-br ${theme?.primary || colorThemes.main.primary}
      bg-clip-text text-transparent
    `}>
      {title}
    </h2>
  </div>
);

const SubTitle = ({ icon: Icon, children, theme }) => (
  <h3 className={`
    text-xl font-semibold mb-4 
    flex items-center gap-2
    ${theme?.text || colorThemes.main.primary}
  `}>
    <Icon className="w-5 h-5" />
    {children}
  </h3>
);

const ListItem = ({ icon: Icon, children, theme }) => (
  <motion.li 
    variants={fadeInUp}
    className={`
      flex items-center gap-3 
      text-gray-600 mb-4
      transition-all duration-300
      ${theme?.hover || colorThemes.main.hover}
    `}
  >
    <Icon className={`
      w-5 h-5 flex-shrink-0
      ${theme?.icon || colorThemes.main.primary}
    `} />
    <span>{children}</span>
  </motion.li>
);

const About = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-indigo-50/50 to-violet-50">
      <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        {/* En-tête */}
        <motion.div 
          className="text-center mb-16"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <motion.h1 
            className={`
              text-5xl font-bold mb-6
              bg-gradient-to-r ${colorThemes.main.primary}
              bg-clip-text text-transparent
            `}
          >
            À propos de F@yClick
          </motion.h1>
          <motion.div 
            className={`
              h-2 w-32 mx-auto rounded-full mb-12
              bg-gradient-to-r ${colorThemes.main.primary}
            `}
            initial={{ width: 0 }}
            animate={{ width: 128 }}
            transition={{ duration: 1, ease: "easeOut" }}
          />

          {/* Carte de présentation */}
          <PremiumCard theme={colorThemes.presentation} delay={0.2} className="mb-12">
            <SectionTitle 
              icon={ShoppingBag} 
              title="Présentation" 
              theme={colorThemes.presentation}
            />
            <p className="text-gray-600 leading-relaxed">
              F@yClick est une plateforme innovante conçue pour simplifier les paiements 
              et la gestion des factures pour les acteurs du secteur formel et informel. 
              Elle permet de dématérialiser les factures et de faciliter les transactions 
              avec un QR code marchand, rendant les paiements accessibles 24/7.
            </p>
          </PremiumCard>
        </motion.div>

        {/* Grille principale */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-12">
          {/* Comment ça marche */}
          <PremiumCard delay={0.2} theme={colorThemes.howItWorks}>
            <SectionTitle 
              icon={Building2} 
              title="Comment ça marche" 
              theme={colorThemes.howItWorks}
            />
            
            <div className="space-y-8">
              <div>
                <SubTitle icon={Users} theme={colorThemes.howItWorks}>
                  Pour les structures marchandes:
                </SubTitle>
                <motion.ul
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={{
                    visible: {
                      transition: {
                        staggerChildren: 0.1
                      }
                    }
                  }}
                >
                  <ListItem icon={UserPlus} theme={colorThemes.howItWorks}>
                    Créer un compte pour votre structure pour recevoir des paiements à distance
                  </ListItem>
                  <ListItem icon={Upload} theme={colorThemes.howItWorks}>
                    Chargez vos listes de produits/articles ou services dans la plateforme
                  </ListItem>
                  <ListItem icon={Send} theme={colorThemes.howItWorks}>
                    F@yclick partage vos factures à chacun de vos clients
                  </ListItem>
                </motion.ul>
              </div>

              <div>
                <SubTitle icon={Wallet} theme={colorThemes.howItWorks}>
                  Pour les clients payeurs:
                </SubTitle>
                <motion.ul
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={{
                    visible: {
                      transition: {
                        staggerChildren: 0.1
                      }
                    }
                  }}
                >
                  <ListItem icon={Search} theme={colorThemes.howItWorks}>
                    Rechercher et payer vos factures avec un numéro Orange Money
                  </ListItem>
                  <ListItem icon={LayoutGrid} theme={colorThemes.howItWorks}>
                    F@yclick organise vos factures et vos paiements
                  </ListItem>
                  <ListItem icon={Receipt} theme={colorThemes.howItWorks}>
                    Vous avez un reçu détaillé de chaque paiement
                  </ListItem>
                </motion.ul>
              </div>
            </div>
          </PremiumCard>

          {/* Avantages */}
          <PremiumCard delay={0.3} theme={colorThemes.advantages}>
            <SectionTitle 
              icon={Gift} 
              title="Avantages" 
              theme={colorThemes.advantages}
            />
            
            <div className="space-y-8">
              <div>
                <SubTitle icon={Building2} theme={colorThemes.advantages}>
                  Pour les PME/PMI et Commerces
                </SubTitle>
                <motion.ul
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={{
                    visible: {
                      transition: {
                        staggerChildren: 0.1
                      }
                    }
                  }}
                >
                  <ListItem icon={ScrollText} theme={colorThemes.advantages}>
                    Factures et reçus 100% numériques
                  </ListItem>
                  <ListItem icon={ShieldCheck} theme={colorThemes.advantages}>
                    Traçabilité complète des paiements
                  </ListItem>
                  <ListItem icon={Timer} theme={colorThemes.advantages}>
                    Journalisation de vos paiements via OM
                  </ListItem>
                </motion.ul>
              </div>

              <div>
                <SubTitle icon={Users} theme={colorThemes.advantages}>
                  Pour vos clients
                </SubTitle>
                <motion.ul
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={{
                    visible: {
                      transition: {
                        staggerChildren: 0.1
                      }
                    }
                  }}
                >
                  <ListItem icon={MonitorSmartphone} theme={colorThemes.advantages}>
                    Paiements à distance
                  </ListItem>
                  <ListItem icon={Shield} theme={colorThemes.advantages}>
                    Élimination des transferts hasardeux
                  </ListItem>
                  <ListItem icon={Clock} theme={colorThemes.advantages}>
                    Flexibilité des paiements 24/7
                  </ListItem>
                </motion.ul>
              </div>
            </div>
          </PremiumCard>
        </div>
      </div>
    </div>
  );
};

export default About;