import React, { useState, useEffect } from 'react';
import {  LucideCheckCircle, Loader } from 'lucide-react';
//import { getReponseApi } from './apis/api_backend';
import { useParams } from 'react-router-dom';
import './styles/success-payment.css';

const SuccessPayment = () => {
  const [facture, setFacture] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { num_facture, ref_destinate } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        console.log("Paramètres reçus:", { num_facture, ref_destinate });
        
        // Faire la requête directement ici plutôt que d'utiliser getReponseApi
        const response = await fetch(`${process.env.REACT_APP_API_URL}/factures/getrecu/${num_facture}/${ref_destinate}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error(`Erreur HTTP! status: ${response.status}`);
        }

        const data = await response.json();
        
        if (data && data.length > 0) {
          setFacture(data[0]);
        } else {
          setError('Facture non trouvée');
        }
      } catch (err) {
        console.error('Erreur:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (ref_destinate && num_facture) {
      fetchData();
    }
  }, [ref_destinate, num_facture]);


  if (loading) {
    return (
      <div className="success-container">
        <div className="loading-container">
          <Loader className="loading-spinner" size={32} />
          <p>Chargement...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="success-container">
        <div className="error-container">
          <div className="error-icon">
            <svg width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
          </div>
          <p className="error-message">{error}</p>
          <button className="return-button" onClick={() => window.location.href = '/'}>
            Retour à l'accueil
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="success-container">
      <div className="success-card">
        <div className="success-icon">
          <LucideCheckCircle />
        </div>

        <h1 className="success-title">Paiement réussi !</h1>
        
        <p className="success-subtitle">
          Votre transaction a été traitée avec succès
        </p>

        {facture && (
          <>
            <div className="info-row">
              <span className="info-label">Référence</span>
              <span className="reference-value">{facture.numrecu}</span>
            </div>
            <div className="info-row">
              <span className="info-label">Structure</span>
              <span className="structure-value">{facture.nom_structure}</span>
            </div>
            <div className="info-row">
              <span className="info-label">Description</span>
              <span className="reference-value">{facture.nom_classe}</span>
            </div>
            <div className="info-row">
              <span className="info-label">Montant</span>
              <span className="amount-value">
                {parseFloat(facture.montant).toLocaleString('fr-FR', {
                  style: 'currency',
                  currency: 'XOF',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                })}
              </span>
            </div>
          </>
        )}

        <button className="return-button" onClick={() => window.location.href = '/'}>
          Retour à l'accueil
        </button>

        <p className="email-notice">
          Ce reçu est disponible sur nos serveurs pendant une (01) année
        </p>
      </div>
    </div>
  );
};

export default SuccessPayment;