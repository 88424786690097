// frontend/src/components/apis/api_backend.js

// Fonction pour récupérer le token JWT
function getToken() {
    return localStorage.getItem('token');
}

// Configuration de base pour l'API
const API_CONFIG = {
    baseUrl: process.env.REACT_APP_API_URL || 'https://backend1payecole-aa57a12e35de.herokuapp.com/api',
    defaultHeaders: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
};

/**
 * Fonction principale pour les appels API
 * @param {string} endpoint - Le chemin de l'endpoint
 * @param {string} method - La méthode HTTP (GET, POST, etc.)
 * @param {object} bodyParams - Les paramètres du corps de la requête
 * @param {object} additionalHeaders - Headers supplémentaires
 * @returns {Promise} - La réponse de l'API
 */
export async function getReponseApi(endpoint, method = 'GET', bodyParams = null, additionalHeaders = {}) {
    const url = `${API_CONFIG.baseUrl}${endpoint}`;
    const token = getToken();

    console.log('BodyParams:', bodyParams);

    // Construction des headers
    const headers = {
        ...API_CONFIG.defaultHeaders,
        ...(token && { 'Authorization': `Bearer ${token}` }),
        ...additionalHeaders
    };

    // Configuration de la requête
    const requestConfig = {
        method,
        headers,
        credentials: 'include', // Important pour CORS avec cookies
        mode: 'cors',
    };

    // Ajout du corps de la requête si nécessaire
    if (bodyParams) {
        requestConfig.body = JSON.stringify(bodyParams);
    }

    try {
        // Logging de l'appel
        console.log(`Appel API: ${method} ${url}`, requestConfig);
        
        const response = await fetch(url, requestConfig);
        
        // Logging de la réponse
        console.log(`Réponse API status: ${response.status}`);

        // Gestion des différents codes de statut
        if (response.status === 401) {
            // Gestion de l'authentification expirée
            localStorage.removeItem('token');
            throw new Error('Session expirée. Veuillez vous reconnecter.');
        }

        if (response.status === 403) {
            throw new Error('Accès non autorisé');
        }

        if (response.status === 404) {
            throw new Error('Ressource non trouvée');
        }

        if (!response.ok) {
            throw new Error(`Erreur serveur: ${response.status}`);
        }

        // Parsing de la réponse
        const data = await response.json();

        // Validation des données reçues
        if (data === null || data === undefined) {
            throw new Error('Réponse vide du serveur');
        }

        // Gestion des différents types de réponses
        if (Array.isArray(data)) {
            return data;
        } else if (typeof data === 'object') {
            return data;
        } else {
            return { data }; // Encapsulation des données primitives
        }

    } catch (error) {
        console.error('Erreur API détaillée:', {
            url,
            method,
            error: error.message,
            stack: error.stack
        });

        // Gestion des erreurs spécifiques
        if (error.message.includes('Failed to fetch')) {
            throw new Error('Impossible de contacter le serveur. Vérifiez votre connexion.');
        }

        // Propagation de l'erreur pour la gestion au niveau du composant
        throw error;
    }
}

// Fonction utilitaire pour vérifier si une réponse est valide
export function isValidResponse(data) {
    if (Array.isArray(data)) {
        return data.length > 0;
    }
    return data && typeof data === 'object' && Object.keys(data).length > 0;
}

// Exemple d'utilisation dans un composant
/* 
try {
    const data = await getReponseApi('/endpoint', 'POST', { key: 'value' });
    if (isValidResponse(data)) {
        // Traitement des données
    }
} catch (error) {
    // Gestion de l'erreur
    console.error('Erreur lors de l\'appel API:', error.message);
}
*/