import React, { useState, useEffect } from 'react';
import { uploadFile } from '../apis/api_upload';
import { getReponseApi } from '../apis/api_backend';
import { toast } from 'react-hot-toast';
import { 
  Building, 
  Upload, 
  Phone, 
  Mail, 
  MapPin,
  Save
} from 'lucide-react';
import '../styles/NewStructure.css'; // Nous réutilisons les mêmes styles

const DEFAULT_LOGO = `${process.env.PUBLIC_URL}/images/default-logo.png`;

const EditStructure = ({ structure , onClose}) => {
  // États simplifiés pour la modification
  const [isLoading, setIsLoading] = useState(false);
  const [typeOptions, setTypeOptions] = useState([]);
  const [logoFile, setLogoFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(structure.logo || DEFAULT_LOGO);
  const [formData, setFormData] = useState({
    adresse: structure.adresse || '',
    mobile_om: structure.mobile_om || '',
    numautorisatioon: structure.numautorisatioon || '',
    nummarchand: structure.nummarchand || '',
    email: structure.email || '',
    logo: structure.logo || DEFAULT_LOGO
  });
  const [errors, setErrors] = useState({});
  const [fieldStatus, setFieldStatus] = useState({});


  useEffect(() => {
    const fetchTypes = async () => {
      try {
        const response = await getReponseApi('/typestructures', 'GET');
        if (response) setTypeOptions(response);
      } catch (error) {
        console.error('Erreur:', error);
      }
    };
    
    fetchTypes();
  }, []);

  useEffect(() => {
    // Mettre à jour le formulaire si la structure change
    console.log('Structure affichée:', structure);
    setFormData({
      adresse: structure.adresse || '',
      mobile_om: structure.mobile_om || '',
      numautorisatioon: structure.numautorisatioon || '',
      nummarchand: structure.nummarchand || '',
      email: structure.email || '',
      logo: structure.logo || DEFAULT_LOGO
    });
    setPreviewUrl(structure.logo || DEFAULT_LOGO);
  }, [structure]);

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLogoFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
        setFormData(prev => ({ ...prev, logo: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const validateField = (name, value) => {
    let error = '';
    switch (name) {
      case 'mobile_om':
        if (value && !/^\d{9}$/.test(value)) error = 'Format invalide (9 chiffres)';
        break;
      case 'email':
        if (value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value))
          error = 'Email invalide';
        break;
      default:
        break;
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    
    const error = validateField(name, value);
    setErrors(prev => ({ ...prev, [name]: error }));
    setFieldStatus(prev => ({ 
      ...prev, 
      [name]: error ? 'error' : value ? 'success' : ''
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const submitFormData = new FormData();
      
      // Comparaison des champs modifiés
      const hasChanged = {
        adresse: formData.adresse !== structure.adresse,
        mobile_om: formData.mobile_om !== structure.mobile_om,
        email: formData.email !== structure.email,
        numautorisatioon: formData.numautorisatioon !== structure.numautorisatioon,
        nummarchand: formData.nummarchand !== structure.nummarchand,
      };

      // Ajout uniquement des champs modifiés ou valeurs par défaut
      submitFormData.append('id_structure', structure.id_structure);
      submitFormData.append('id_type', structure.id_type);
      submitFormData.append('nom_structure', structure.nom_structure);
      
      // Adresse
      if (hasChanged.adresse) {
        submitFormData.append('adresse', formData.adresse);
      } else {
        submitFormData.append('adresse', structure.adresse || 'SENEGAL');
      }

      // Mobile OM
      if (hasChanged.mobile_om) {
        submitFormData.append('mobile_om', formData.mobile_om);
      } else {
        submitFormData.append('mobile_om', structure.mobile_om);
      }

      // Email
      if (hasChanged.email) {
        submitFormData.append('email', formData.email);
      } else {
        submitFormData.append('email', structure.email || '');
      }

      // Numéro d'autorisation
      if (hasChanged.numautorisatioon) {
        submitFormData.append('numautorisatioon', formData.numautorisatioon);
      } else {
        submitFormData.append('numautorisatioon', structure.numautorisatioon || 'sen_auto2024');
      }

      // Numéro marchand
      if (hasChanged.nummarchand) {
        submitFormData.append('nummarchand', formData.nummarchand);
      } else {
        submitFormData.append('nummarchand', structure.nummarchand || '');
      }

      // Mobile Wave (toujours la valeur par défaut)
      submitFormData.append('mobile_wave', structure.mobile_wave || '');
      
      // Id localité (toujours la valeur par défaut)
      submitFormData.append('id_localite', structure.id_localite || '1');

      // Logo
      if (logoFile) {
        submitFormData.append('logo', logoFile);
      }

      // Debug : Vérifier les données avant envoi
      console.log('Données à envoyer :');
      for (const [key, value] of submitFormData.entries()) {
        console.log(`${key}:`, value);
      }

      const response = await uploadFile(`/structures/${structure.id_structure}`, 'PUT', submitFormData);

      if (response && response.success !== false) {
        toast.success('Structure modifiée avec succès!');
        onClose(true);
      } else {
        throw new Error(response?.error || 'Erreur lors de la modification');
      }
    } catch (error) {
      console.error('Erreur:', error);
      toast.error(error.message || 'Erreur lors de la modification');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="newstructurepage">
      <div className="new-structure-container">
        <div className="form-card">
          <div className="card-header">
            <h1>
              <Building className="header-icon" />
              Modifier {structure.nom_structure}
            </h1>
          </div>

          <form onSubmit={handleSubmit} className="structure-form">
            <div className="form-content">
            <div className="logo-upload-section">
            <div 
            className="logo-preview" 
            style={{ backgroundImage: `url(${previewUrl})` }}
            >
            <input
                type="file"
                onChange={handleLogoChange}
                accept="image/*"
                id="logo-input"
                className="hidden-input"
            />
            <label htmlFor="logo-input" className="upload-overlay">
                <Upload size={24} />
                <span>Changer le logo</span>
            </label>
            </div>
        </div>

        {/* Type de structure (grisé) */}
        <div className="form-group">
            <select
            name="id_type"
            value={structure.id_type}
            className="input-disabled"
            disabled
            >
            <option value="">Sélectionnez un type</option>
            {typeOptions.map(type => (
                <option key={type.id_type} value={type.id_type}>
                {type.nom_type}
                </option>
            ))}
            </select>
        </div>
              <div className="form-group">
                <div className="input-wrapper">
                  <MapPin className="field-icon" size={18} />
                  <input
                    type="text"
                    name="adresse"
                    value={formData.adresse}
                    onChange={handleChange}
                    className={fieldStatus.adresse}
                    placeholder="Adresse complète"
                  />
                </div>
                {errors.adresse && <span className="error-message">{errors.adresse}</span>}
              </div>

              <div className="form-group">
                <div className="input-wrapper">
                  <Phone className="field-icon" size={18} />
                  <input
                    type="text"
                    name="mobile_om"
                    value={formData.mobile_om}
                    onChange={handleChange}
                    className={fieldStatus.mobile_om}
                    placeholder="77XXXXXXX"
                    maxLength="9"
                  />
                </div>
                {errors.mobile_om && <span className="error-message">{errors.mobile_om}</span>}
              </div>

              <div className="form-group">
                <div className="input-wrapper">
                  <Mail className="field-icon" size={18} />
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={fieldStatus.email}
                    placeholder="email@domaine.com"
                  />
                </div>
                {errors.email && <span className="error-message">{errors.email}</span>}
              </div>

              <div className="form-group">
                <div className="input-wrapper">
                  <Building className="field-icon" size={18} />
                  <input
                    type="text"
                    name="numautorisatioon"
                    value={formData.numautorisatioon}
                    onChange={handleChange}
                    className={fieldStatus.numautorisatioon}
                    placeholder="Numéro d'autorisation"
                  />
                </div>
              </div>

              <div className="form-group">
                <div className="input-wrapper">
                  <Building className="field-icon" size={18} />
                  <input
                    type="text"
                    name="nummarchand"
                    value={formData.nummarchand}
                    onChange={handleChange}
                    className={fieldStatus.nummarchand}
                    placeholder="Numéro marchand"
                  />
                </div>
              </div>
            </div>

            <div className="form-navigation">
                <button
                    type="button"
                    className="nav-button prev"
                    onClick={() => onClose(false)}
                >
                    Annuler
                </button>
                <button
                    type="submit"
                    className="submit-button"
                    disabled={isLoading}
                >
                    {isLoading ? 'Enregistrement...' : 'Enregistrer'}
                    <Save className="button-sparkle" size={16} />
                </button>
                </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditStructure;