import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LogIn, CreditCard,ArrowRight,Building,Sparkles } from 'lucide-react';
import './styles/Home.css';
import Footer from './Footer';

function Home() {
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const images = [
    `${process.env.PUBLIC_URL}/images/bannieres/p1.webp`,
    `${process.env.PUBLIC_URL}/images/bannieres/p2.webp`,
    `${process.env.PUBLIC_URL}/images/bannieres/p3.webp`,
    `${process.env.PUBLIC_URL}/images/bannieres/p5.webp`,
    `${process.env.PUBLIC_URL}/images/bannieres/p4.webp`,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFade(true);
      }, 1000);
    }, 6000);

    return () => clearInterval(interval);
  }, [images.length]);

  const handleLoginClick = () => {
    navigate('/login');
  };
  const goToAPaye = () => {
    navigate('/jepaye');
  };
  const handleNavigate = () => {
    navigate('/inscription');
  };

  return (
    <div
      className={`home-background ${fade ? 'fade-in' : 'fade-out'}`}
      style={{ backgroundImage: `url(${images[currentImageIndex]})` }}
    >
      <div className="top-buttons">
        
        <button className="btnPrimary" onClick={handleLoginClick}>
          <LogIn className="button-icon" size={20} />
          Entrer
        </button>
      </div>

      <header className="home-header">
        <h1 className="home-title">F@yClick</h1>
        <p className="home-tagline">
          Sans papiers, sans déplacements payer en toute tranquillité
        </p>
      </header>

      <div className="jepay-container" onClick={goToAPaye} style={{ cursor: 'pointer' }}>
                  <div className="jepay-button">
                    <div className="jepay-content">
                    <CreditCard className="button-icon" size={24} />
                      <span> Payer votre facture </span>                      
                    </div>                    
                  </div>
                </div>
   
   

               
                <div className="create-mastructure-container" onClick={handleNavigate} style={{ cursor: 'pointer' }}>
                  <div className="create-mastructure-button">
                    <div className="button-content">
                      <Building className="mastructure-icon" size={24} />
                      <span>Créer votre structure</span>
                      <ArrowRight className="arrow-icon" size={20} />
                    </div>
                    <div className="sparkles">
                      <Sparkles className="sparkle-icon" size={16} />
                    </div>
                  </div>
                </div>
      <Footer />
    </div>
  );
}

export default Home;