import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Calendar,Loader2, Phone, Building, FileText, GraduationCap, Printer, CheckCircle } from 'lucide-react';
import '../styles/ticketRecu.css';

const ConfirmationPayment = () => {
  const { num_facture, nom_structure } = useParams();
  const navigate = useNavigate();
  const [facture, setFacture] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);




  const formatDate = (date) => {
    return new Intl.DateTimeFormat('fr-FR', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    }).format(new Date(date)).toUpperCase();
  };

  useEffect(() => {
    // Initialisation de AOS
    const loadAOS = async () => {
      const AOS = await import('aos');
      AOS.init({
        duration: 800,
        once: true
      });
    };
    loadAOS();
 

    const fetchRecu = async () => {
      if (!num_facture || !nom_structure) {
        setError("Numéro de facture ou nom de structure manquant");
        setLoading(false);
        return;
      }

      console.log('Chargement du reçu pour:', { num_facture, nom_structure });

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/factures/getrecu/${num_facture}/${nom_structure}`,
          {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            mode: 'cors'
          }
        );

        if (!response.ok) {
          console.error('Erreur réponse:', response.status, response.statusText);
          throw new Error(`Erreur HTTP: ${response.status}`);
        }

        const data = await response.json();
        console.log("Reçu récupéré:", data);

        if (!data || data.length === 0) {
          throw new Error('Aucune donnée de reçu trouvée');
        } else {
          
          setFacture(data);
        }

      } catch (err) {
        console.error("Erreur détaillée:", {
          message: err.message,
          stack: err.stack,
          num_facture,
          nom_structure
        });
        setError(err.message || 'Erreur lors de la récupération du reçu');
      } finally {
        setLoading(false);
      }
    };

    fetchRecu();
  }, [num_facture, nom_structure]);

  if (loading) {
    return (
      <div className="payment-card-bg flex items-center justify-center min-h-screen">
        <div className="flex flex-col items-center gap-2">
          <Loader2 className="h-8 w-8 animate-spin text-green-500" />
          <p className="text-gray-600 text-sm">
            Chargement du reçu de paiement...
          </p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="payment-card-bg flex items-center justify-center min-h-screen p-4">
        <div className="max-w-md w-full bg-white rounded-3xl shadow-xl p-6">
          <p className="text-red-600 text-center mb-4">{error}</p>
          <button 
            onClick={() => navigate('/')}
            className="w-full bg-red-500 text-white py-3 px-4 rounded-full hover:bg-red-600 transition-all duration-300 hover:shadow-lg transform hover:-translate-y-1"
          >
            Retour à l'accueil
          </button>
        </div>
      </div>
    );
  }

  if (!facture) return null;
  return (
    <motion.div 
      className="receipt-popup"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
     
    >
      <motion.div 
        className="receipt-container"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 20, opacity: 0 }}
      >
        <button 
          type="button"
          className="close-button" 
          onClick={() => navigate('/')}
        >
          ×
        </button>

        <div className="receipt-header">
          <div className="receipt-logo">
            <img src={facture.logo} alt="Logo" />
          </div>
          <div className="receipt-number">
            N° {facture.numrecu}
          </div>
          <div className="receipt-date">
            <Calendar size={16} />
            Payée le {formatDate(facture.tms_update)}
          </div>
        </div>

        <div className="receipt-body">
          {/* Informations client */}
          <div className="receipt-section">
            <h3 className="section-title">
              <span>Informations Client</span>
            </h3>
            <div className="info-grid">
              <div className="info-item">
                <div className="info-label">
                  <Phone size={16} />
                  Téléphone
                </div>
                <div className="info-value">{facture.tel_client}</div>
              </div>
              <div className="info-item">
                <div className="info-label">Nom</div>
                <div className="info-value">{facture.nom_client}</div>
              </div>
            </div>
          </div>

          {/* Détails paiement */}
          <div className="receipt-section">
            <h3 className="section-title">
              <span>Détails Paiement</span>
            </h3>
            <div className="info-grid">
              <div className="info-item">
                <div className="info-label">
                  <Building size={16} />
                  Structure
                </div>
                <div className="info-value">{facture.nom_structure}</div>
              </div>
              <div className="info-item">
                <div className="info-label">
                  <FileText size={16} />
                  N° Facture
                </div>
                <div className="info-value">{facture.num_facture}</div>
              </div>
              <div className="info-item">
                <div className="info-label">
                  <GraduationCap size={16} />
                  Désignation
                </div>
                <div className="info-value">{facture.nom_classe}</div>
              </div>
            </div>
          </div>

          {/* Montant et statut */}
          <div className="receipt-total">
            <div className="total-row">
              <span className="total-label">Montant Total</span>
              <span className="total-amount">{facture.montant} F</span>
            </div>
            <div className="receipt-status">
              <CheckCircle size={16} />
              {facture.libelle_etat}
            </div>
          </div>

          {/* Bouton d'impression */}
          <button className="print-button" onClick={() => window.print()}>
            <Printer size={18} />
            Imprimer le reçu
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};


export default ConfirmationPayment;