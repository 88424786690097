import React, { useState, useEffect, useMemo } from 'react';
import { X, Send, ChevronLeft, ChevronRight, Search,MessageCircle, MessageSquare  } from 'lucide-react';

import { getReponseApi } from '../apis/api_backend';
import '../styles/ArticleManagerModal.css';
import { generateFactureUrl } from '../../config/urls';

const FactureSMSAlert = ({ isOpen, onClose, factures }) => {
  const [facturesList, setFacturesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 25;
  const [showSendModal, setShowSendModal] = useState(false);

  useEffect(() => {
    if (factures && factures.length > 0) {
      console.log('Factures reçues:', factures);
      setFacturesList(factures);
    }
  }, [factures]);

  const formatPeriode = (mois, annee) => {
    const moisNoms = {
      '1': 'JAN',
      '2': 'FEV',
      '3': 'MAR',
      '4': 'AVR',
      '5': 'MAI',
      '6': 'JUN',
      '7': 'JUL',
      '8': 'AOU',
      '9': 'SEP',
      '10': 'OCT',
      '11': 'NOV',
      '12': 'DEC'
    };
    console.log('mois : ',mois);
    if (!mois) return '11-2023';
    // Assurez-vous que mois est une chaîne
    const moisStr = mois.toString();
    return `${moisNoms[moisStr]}-${annee}`;
  };
  

  useEffect(() => {
    if (isOpen) {
      setSearchTerm('');
      setCurrentPage(1);
      setError('');
    }
  }, [isOpen]);

  const filteredFactures = useMemo(() => {
    if (!searchTerm.trim()) return facturesList;
    
    const searchTermLower = searchTerm.toLowerCase();
    return facturesList.filter(facture => 
      facture.nom_client.toLowerCase().includes(searchTermLower)
    );
  }, [facturesList, searchTerm]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  const totalPages = Math.ceil(filteredFactures.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentFactures = filteredFactures.slice(startIndex, endIndex);

  const totalFactures = filteredFactures.length;
  
  const getTotalMontant = useMemo(() => {
    return filteredFactures.reduce((total, facture) => {
      return total + parseFloat(facture.montant);
    }, 0);
  }, [filteredFactures]);


  const handleSendWhatsApp = async () => {
    console.log('🚀 Démarrage de l\'envoi WhatsApp...');
    try {
      for (const facture of filteredFactures) {
        const factureUrl = generateFactureUrl(facture.num_facture);
        const message = encodeURIComponent(`${facture.nom_structure} vous rappelle Facture de ${formatPeriode(facture.nmois, facture.nannee)}. Consultez ici: ${factureUrl}`);
        const phoneNumber = facture.tel_client.startsWith('221') ? facture.tel_client : `221${facture.tel_client}`;
        window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank');
      }
      onClose();
    } catch (error) {
      console.error('Erreur lors de l\'envoi WhatsApp:', error);
      setError('Erreur lors de l\'envoi WhatsApp');
    }
  };
  
  const handleSendSMS = async () => {
    console.log('🚀 Démarrage de l\'envoi des SMS...');
    console.log('📋 Nombre de factures à traiter:', filteredFactures.length);
  
    try {
      setLoading(true);
      setError('');
  
      const successfulSends = [];
      const failedSends = [];
  
      console.log('🏢 Structure courante:', {
        nom: factures[0]?.nom_structure,
        id: factures[0]?.id_structure
      });
  
      for (const facture of filteredFactures) {
        console.log('\n📝 Traitement de la facture:', {
          client: facture.nom_client,
          tel: facture.tel_client,
          numFactures: facture.num_facture.split('@').length,
          montant: facture.montant
        });
  
        try {
           // Construction de l'URL pour les factures
          //const factureUrl = `${process.env.PUBLIC_URL}/facture/${facture.num_facture}`;
          const factureUrl = generateFactureUrl(facture.num_facture);
          console.log('🔗 URL générée:', factureUrl);
          //const facturesCount = getFactureCountDisplay(facture.num_facture);
          //const montantFormate = formatMontant(facture.montant);
          const message = `${facture.nom_structure} vous rappelle Facture de 
          ${formatPeriode(facture.nmois, facture.nannee)}. 
          Consultezici: ${factureUrl}`      
          //const message = `${facture.nom_client}, vous avez ${facturesCount} en attente de paiement d'un montant total de ${montantFormate}. ${facture.nom_structure}`;
          
          console.log('✉️ Message préparé:', {
            longueur: message.length,
            message: message
          });
  
          const requestData = {
            client: 'ICELABOSOFT',
            telephone: facture.tel_client,
            message: message,
            origine: facture?.nom_structure || 'RAPPEL_FACTURE'
          };
  
          console.log('📤 Envoi de la requête API:', {
            endpoint: '/factures/send_alert',
            data: requestData
          });
  
          const response = await getReponseApi('/factures/send_alert', 'POST', requestData);
          console.log('📨 Réponse reçue:', response);
  
          if (response?.success) {
            console.log('✅ SMS envoyé avec succès pour:', facture.nom_client);
            successfulSends.push(facture.nom_client);
          } else {
            console.error('❌ Échec de l\'envoi pour:', facture.nom_client, response);
            failedSends.push(facture.nom_client);
          }
  
        } catch (smsError) {
          console.error('🚨 Erreur lors de l\'envoi du SMS:', {
            client: facture.nom_client,
            erreur: smsError.message
          });
          failedSends.push(facture.nom_client);
        }
      }
  
      // Résumé des envois
      console.log('\n📊 Résumé des envois:', {
        total: filteredFactures.length,
        réussis: successfulSends.length,
        échoués: failedSends.length
      });
  
      if (successfulSends.length > 0) {
        const successMessage = `SMS envoyés avec succès pour ${successfulSends.length} client(s)`;
        console.log('🎉', successMessage);
        alert(successMessage);
      }
      
      if (failedSends.length > 0) {
        const errorMessage = `Échec d'envoi pour ${failedSends.length} client(s): ${failedSends.join(', ')}`;
        console.error('📛', errorMessage);
        setError(errorMessage);
      }
  
      if (successfulSends.length > 0 && failedSends.length === 0) {
        console.log('✨ Fermeture du modal - Tous les envois ont réussi');
        onClose();
      }
  
    } catch (err) {
      const errorMessage = err.message || 'Erreur lors de l\'envoi des SMS';
      console.error('💥 Erreur générale:', errorMessage);
      setError(errorMessage);
    } finally {
      console.log('🏁 Fin du processus d\'envoi');
      setLoading(false);
    }
  };
  
  const getFactureCountDisplay = (numFacture) => {
    // Compte le nombre de @ et ajoute 1 pour avoir le nombre total de factures
    const count = (numFacture.match(/@/g)?.length + 1) || 1;
    return `${count} facture${count > 1 ? 's' : ''}`;
  };

  const formatMontant = (montant) => {
    const nombre = parseFloat(montant);
    return `${nombre.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}F`;
  };

  if (!isOpen) return null;

  return (
    <div className="am-modal-overlay">
      <div className="am-modal-content">
        <div className="am-modal-header">
          <h2>Gestion des Alertes SMS Factures</h2>
          <button onClick={onClose} className="am-close-button">
            <X size={18} />
          </button>
          {error && (
            <div className="error-message mb-4 p-3 bg-red-100 text-red-700 rounded">
              {error}
            </div>
          )}
        </div>
        
        <div className="am-content">
          <div className="am-search-section">
            <div className="am-search-container">
              <Search size={20} className="am-search-icon" />
              <input
                type="text"
                placeholder="Rechercher un client..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="am-search-input"
              />
            </div>
            <div className="am-articles-count">
              {totalFactures} facture{totalFactures > 1 ? 's' : ''} trouvée{totalFactures > 1 ? 's' : ''}
            </div>
          </div>

          <div className="am-table-container">
            <table className="am-table">
              <thead>
                <tr>
                  <th>Période</th>
                  <th>Client</th>
                  <th>Montant</th>
                  <th>Téléphone</th>
                </tr>
              </thead>
              <tbody>
                {currentFactures.map((facture) => (
                  <tr key={facture.id_facture}>
                    <td>{formatPeriode(facture.nmois, facture.nannee)}</td>
                    <td>{facture.nom_client} ({getFactureCountDisplay(facture.num_facture)})</td>
                    <td>{formatMontant(facture.montant)}</td>
                    <td>{facture.tel_client}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="am-total-section">
            <div className="am-total-amount">
              Montant Total: {formatMontant(getTotalMontant)}
            </div>
          </div>

          <div className="am-pagination">
            <button 
              onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
              disabled={currentPage === 1}
              className="am-pagination-button"
            >
              <ChevronLeft size={20} />
            </button>
            
            <span className="am-pagination-info">
              Page {currentPage} sur {totalPages}
            </span>
            
            <button 
              onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
              disabled={currentPage === totalPages}
              className="am-pagination-button"
            >
              <ChevronRight size={20} />
            </button>
          </div>

          <div className="am-button-container">
            <button 
              onClick={onClose}
              className="am-cancel-button"
            >
              Annuler
            </button>
            <button
                onClick={() => setShowSendModal(true)}
                disabled={loading}
                className="am-save-button"
                title="Envoyer les notifications"
              >
                {loading ? (
                  <span className="am-loading-spinner" />
                ) : (
                  <Send size={24} />
                )}
              </button>

          </div>
        </div>
      </div>
      {showSendModal && (
  <div className="send-method-modal">
    <div className="send-method-content">
      <h3>Choisir le mode d'envoi</h3>
      <div className="send-method-buttons">
        <button
          className="send-method-button sms"
          onClick={() => {
            setShowSendModal(false);
            handleSendSMS();
          }}
        >
          <MessageCircle size={24} />
          <span>Par SMS</span>
        </button>
        <button
          className="send-method-button whatsapp"
          onClick={() => {
            setShowSendModal(false);
            handleSendWhatsApp();
          }}
        >
          <MessageSquare size={24} />
          <span>Par WhatsApp</span>
        </button>
      </div>
      <button
        className="send-method-cancel"
        onClick={() => setShowSendModal(false)}
      >
        Annuler
      </button>
    </div>
  </div>
)}
    </div>
  );
};

export default FactureSMSAlert;