const getBaseUrl = () => {
    // En développement
    if (process.env.NODE_ENV === 'development') {
      return 'http://localhost:3000';
    }
    
    // En production
    return 'https://fayclick.net';
  };
  
  export const config = {
    BASE_URL: getBaseUrl(),
    // Ajoutez d'autres configurations URL ici si nécessaire
  };
  
  // Helper pour générer l'URL de facture
  export const generateFactureUrl = (numFacture) => {
    return `${config.BASE_URL}/facture/${numFacture}`;
  };